export default [

	{
		"value": "DE",
		"label": "Germany",
		"age": 18,
	},
	{
		"value": "AT",
		"label": "Austria",
		"age": 18,
	},
	{
		"value": "CH",
		"label": "Switzerland",
		"age": 18,
	},
	{
		"value": "ROTW",
		"label": "Sonstige",
		"age": 21,
	}
]

