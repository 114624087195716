import React, {Component} from 'react';
import './Button.scss'
import {Button} from "../button/Button";
import PropTypes from 'prop-types';

class ButtonAnchor extends Component {

	render() {

		let {href, target, ...passThroughProps} = this.props;

		return (
			<>
				{this.props.disabled
					?
					<Button {...passThroughProps}/>
					:
					<a href={href}
					   target={target}
					   className={'button__anchor--fit'}
					>
						<Button {...passThroughProps} />
					</a>
				}
			</>
		)
	}
}

ButtonAnchor.propTypes = {
	/**
	 * **REQUIRED**: for type: **anchor**
	 */
	href: PropTypes.string.isRequired,

	/**
	 * OPTIONAL: @default '_self'
	 */
	target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top']),

	/**
	 * OPTIONAL: disables component
	 */
	disabled: PropTypes.bool,

	/**
	 * **REQUIRED**: Label of the button
	 */
	label: PropTypes.string.isRequired,

	/**
	 * OPTIONAL: unique id of the button
	 */
	uid: PropTypes.string,

	/**
	 * OPTIONAL: button title
	 */
	title: PropTypes.string,

	/**
	 * OPTIONAL: icon-string @see 'utils/Mappings.js (iconMap)'
	 */
	icon: PropTypes.string,

	/**
	 * OPTIONAL:
	 */
	appearance: PropTypes.oneOf(['primary', 'secondary', 'important', 'main-navigation', 'dark']),

	/**
	 * OPTIONAL:
	 * @default 'full-width'
	 */
	width: PropTypes.oneOf(['full-width', 'fit', 'fixed']),

	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.*
	 */
	additionalClasses: PropTypes.string,

	/**
	 * use round borders on buttons
	 * @default: true
	 */
	useRoundBorder: PropTypes.bool
};

export {
	ButtonAnchor as default,
	ButtonAnchor
}
