import React, {Component} from 'react';
import './Button.scss'
import classNames from "classnames";
import PropTypes from 'prop-types';
import {Icon, ICON_SIZE} from "../icon/Icon";

class Button extends Component {

	render() {

		const classes = classNames(
			'button',
			{
				[`button--${this.props.width}`]: this.props.width,
				[`button--${this.props.appearance}`]: this.props.appearance
			},
			{'button--round-border': this.props.useRoundBorder},
			{'button--is-loading': this.props.isLoading},
			this.props.additionalClasses
		);

		const icon = this.props.icon && <Icon name={this.props.icon} additionalClasses={'button__icon'} size={ICON_SIZE.XS}/>;
		const label = this.props.label &&
			<span className={classNames('button__label', {'dotted': this.props.underline})}>{this.props.label}</span>;


		return <button
			className={classes}
			data-uid={this.props.uid}
			title={this.props.title}
			disabled={(this.props.disabled || this.props.isLoading) ? 'disabled' : null}
			onClick={this.props.onClick}
			type={this.props.type}
		>
			{label}{icon}
		</button>
	}
}

Button.propTypes = {
	/**
	 * **REQUIRED**: for type: **submit** and **default**
	 */
	onClick: PropTypes.func,
	/**
	 * **REQUIRED**: Label of the button
	 */
	label: PropTypes.string.isRequired,
	/**
	 * OPTIONAL: unique id of the button
	 */
	uid: PropTypes.string,
	/**
	 * OPTIONAL: button title
	 */
	title: PropTypes.string,
	/**
	 * OPTIONAL: disables component
	 */
	disabled: PropTypes.bool,

	icon: PropTypes.string,
	/**
	 * OPTIONAL: Type of the button @see **Types Section** for more information
	 */
	type: PropTypes.oneOf(['button', 'submit']),
	/**
	 * OPTIONAL:
	 */
	appearance: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'important', 'main-navigation', 'ghost']),
	/**
	 * OPTIONAL:
	 * @default 'full-width'
	 */
	width: PropTypes.oneOf(['full-width', 'fit', 'fixed']),
	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.*
	 */
	additionalClasses: PropTypes.string,
	/**
	 * use round borders on buttons
	 * @default: true
	 */
	useRoundBorder: PropTypes.bool,
	/**
	 * adds an icon with loading animation and set button disabled
	 * @default: true
	 */
	isLoading: PropTypes.bool
};

Button.defaultProps = {
	label: 'please_enter_a_proper_label',
	appearance: 'primary',
	type: 'button',
	target: '_self',
	useRoundBorder: true,
	width: 'fit',
	isLoading: false,
	icon: 'arrow-right'
};

export {
	Button as default,
	Button
}
