import React, {Component} from 'react';

import {firebaseConnector as firebase} from "../firebase/Firebase";
import {phaseProvider} from "./PhaseProvider";

function withDataProvider(WrappedComponent, {endpoint}) {
	class WithDataProvider extends Component {

		/**
		 * constructor
		 * default data is isLoading: true to pass a indicator for loading process to wrapped component
		 * @param props
		 */
		constructor(props) {
			super(props);
			this.state = {
				data: null,
				firebase: firebase
			};
			this.dataPromise = firebase.getNode(endpoint);
		}

		/**
		 * Update data with current phase
		 */
		updateData() {
			if (this.dataPromise) {
				this.dataPromise.then((data) => {
					const phase = phaseProvider.getPhase();
					if (data?.phases?.[phase]) {
						this.setState({data: data.phases[phase]});
					} else if (data) {
						this.setState({data});
					}
				});
			}
		}

		/**
		 * Try to fetch data based on current phase
		 */
		componentDidMount() {
			this.data = null;
			this.dataProviderId = phaseProvider.register(this);
			this.updateData();
		}

		/**
		 * Ignore phase changes from here on
		 */
		componentWillUnmount() {
			if (this.dataProviderId) {
				phaseProvider.unregister(this.dataProviderId);
			}
		}

		/**
		 * Hit once phase property has changed
		 * @param prevProps
		 * @param prevState
		 * @param snapshot
		 */
		componentDidUpdate(prevProps, prevState, snapshot) {
			if (prevState && prevState.phase !== this.state.phase) {
				this.updateData();
			}
		}

		/**
		 * Pass state and props as props to wrapped component and force update on change
		 * @return {*}
		 */
		render() {
			return <WrappedComponent {...this.state.data} {...this.props} firebase={firebase}/>;
		}
	}

	WithDataProvider.displayName = `WithDataProvider(${getDisplayName(WrappedComponent)})`;
	return WithDataProvider;
}

function getDisplayName(WrappedComponent) {
	return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export {
	withDataProvider as default,
	withDataProvider
};
