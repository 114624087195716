import classNames from "classnames";
import {StorageManager} from "../../vendor/utils/StorageManager";
import {Layout} from "../Layout";
import {TimelineWithDataProvider as Timeline} from "../../components/organisms/timeline/Timeline";
import {AgeGate} from "../agegate/AgeGate";

const TimelinePage = (props) => {

	const classes = classNames(
		'timeline-page',
	);

	return (
		<>
			<Layout>
				<div className={classes}>
					{StorageManager.getCookie('isAged') === 'true' ?
						<Timeline/>
						:
						<AgeGate/>
					}
				</div>
			</Layout>
		</>
	);

};

export {
	TimelinePage as default,
	TimelinePage
}
