import app from 'firebase/app';
import 'firebase/database';
import 'firebase/firestore';
import fallBackData from '../lared-jaegermeister-denkathon-db'

class Firebase {

	constructor(useFallback) {
		this.useFallback = useFallback;
	}

	/**
	 * get Node of provided data on root level
	 * @param key
	 * @return {Promise<unknown>|Promise<firebase.database.DataSnapshot>}
	 */
	getNode(key) {
		if (this.useFallback && fallBackData[key]) {
			return Promise.resolve(fallBackData[key])
		}

		const ref = app.database().ref(key);
		return ref.once('value').then((snapshot) => snapshot.val())
	}
}

const firebaseConnector = new Firebase(true);

export {
	Firebase as default,
	Firebase,
	firebaseConnector
};
