import React, {Component} from 'react';

function withTitleChange(WrappedComponent,{titleName}) {
	class WithTitleChange extends Component {

		componentDidMount() {
			document.title = titleName;
		}

		render() {
			//filter props to pass
			return <WrappedComponent {...this.props} />;
		}
	}

	WithTitleChange.propTypes = {
	};

	WithTitleChange.displayName = `WithTitleChange(${getDisplayName(WrappedComponent)})`;
	return WithTitleChange;
}



function getDisplayName(WrappedComponent) {
	return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withTitleChange;
