import React, {Component} from 'react';
import './Image.scss'
import {BREAKPOINTS} from "../../../vendor/utils/BreakpointUtils";
import classNames from 'classnames';
import {transparentPNG_1x1} from "../../../vendor/utils/Utils";
import PropTypes from "prop-types";

class Image extends Component {

	/**
	 * @param {Object}          props
	 * @param {String}          props.url
	 * @param {String}          [props.alt]
	 * @param {SrcSet}          [props.srcSet]
	 * @param {Sizes}           [props.sizes]
	 * @param {Ratio}           [props.ratio]
	 * @param {String}          [props.media]
	 *
	 */
	constructor(props) {
		super(props);

		this.loadHandler = this.loadHandler.bind(this);
		this.widthChange = this.widthChange.bind(this);

		let isVisible = true;

		//add media query listener and check image should be loaded and visible oder not

		if (props.media) {
			this.mediaQuery = window.matchMedia(props.media);
			this.mediaQuery.addListener(this.widthChange);
			isVisible = this.mediaQuery.matches
		}

		this.state = {
			minWidth: null,
			isLoading: false,
			isVisible: isVisible,
			isAlreadyLoaded: isVisible
		};
	}

	/**
	 * Check Media Query List matches to media attribute and set states
	 *
	 */
	widthChange() {
		const isVisible = this.mediaQuery.matches;

		this.setState({
			isVisible: isVisible,
			isAlreadyLoaded: isVisible || this.state.isAlreadyLoaded,
			// isLoading: isVisible
		})
	}

	/**
	 * Remove Media Query List listener if component unmount
	 */
	componentWillUnmount() {
		clearTimeout(this.startLoadingAnimationTimeout);
		if (this.props.media) {
			this.mediaQuery.removeListener(this.widthChange);
		}
	}

	componentDidMount() {
		clearTimeout(this.startLoadingAnimationTimeout);
		this.startLoadingAnimationTimeout = setTimeout(() => {
			this.setState({isLoading: true})
		}, 200)
	}

	/**
	 * Load Hanlder for <img>-Element for stop loading animation and set min width for images with ratio
	 *
	 * @param {Object} e
	 */
	loadHandler(e) {
		const image = e.target;
		let minWidth = null;

		if (this.props.ratio) {
			const imageRatio = this.getRatioValue({width: image.naturalWidth, height: image.naturalHeight});
			const wrapperRatio = this.getRatioValue(this.props.ratio);
			minWidth = wrapperRatio > imageRatio && (wrapperRatio / imageRatio * 100) + '%';
		}

		clearTimeout(this.startLoadingAnimationTimeout);

		this.setState({
			minWidth: minWidth,
			isLoading: false,
		})
	}

	/**
	 * Convert srcSet object to valid srcSet attribute for <img> element. For database images it assemble srcSet object at first and then
	 * convert to source set string
	 *
	 * @example
	 *        Image.getSrcSet({
	 *			ultraWideDesktop: 'https://test.test/img/extraLarge.jpg',
	 *			wideDesktop: 'https://test.test/img/large.jpg',
	 *			desktop: 'https://test.test/img/medium.jpg',
	 *			tablet: 'https://test.test/img/small.jpg',
	 *		})
	 *
	 *        => return 'https://test.test/img/extraLarge.jpg 1920w, https://test.test/img/large.jpg 1440w, https://test.test/img/medium.jpg 1024w, https://test.test/img/small.jpg 768w'
	 *
	 * @param {SrcSet} srcSet
	 *
	 * @return {string | null} srcSetString
	 *
	 */
	getSrcSet(srcSet) {

		if (!srcSet) {
			return null
		}

		let srcSetString = '';

		for (let viewportName of Object.keys(srcSet)) {
			const src = srcSet[viewportName];

			if (src) {
				const viewportSize = this.getBreakpointSize(viewportName);

				if (viewportSize) {
					srcSetString += src + " " + viewportSize + "w, ";
				}
			}
		}

		return srcSetString;
	}

	/**
	 * Convert sizes object to valid sizes attribute for <img> element
	 *
	 * @example
	 *        Image.getSizes({
	 *			ultraWideDesktop: '700px',
	 *			wideDesktop: '33vw',
	 *			desktop: '50vw',
	 *			tablet: '75vw',
	 *			default: 100vw
	 *		})
	 *
	 *        => return '(min-width: 1920) 700px, (min-width: 1440) 33vw, (min-width: 1024px) 50vw, (min-width: 768px) 75vw, 100vw'
	 *
	 * @param {Sizes} sizes
	 *
	 * @return {string | null}
	 */
	getSizes(sizes) {

		if (!sizes) {
			return null
		}

		let sizesString = '';

		for (let viewportName of Object.keys(sizes)) {
			const size = sizes[viewportName];

			if (size) {
				const viewportSize = this.getBreakpointSize(viewportName);

				if (viewportSize) {
					sizesString += "(min-width: " + viewportSize + "px) " + size + ", ";
				} else {
					sizesString += size;
				}
			}
		}

		return sizesString;
	}

	/**
	 * Return breakpoint size based on BREAKPOINT object
	 *
	 * @example
	 *        Image.getBreakpointSize('desktop')
	 *        => return 1024
	 *
	 * @param {String} breakpointName
	 * @return {Number}
	 */
	getBreakpointSize(breakpointName) {
		return BREAKPOINTS[breakpointName];
	}

	/**
	 * Calculate ratio value
	 *
	 * @param {Ratio} ratio
	 *
	 * @return {Number}
	 */
	getRatioValue(ratio) {
		if (ratio.height && ratio.width) {
			return ratio.height / ratio.width;
		}
	}

	render() {
		let url = this.props.url;

		const ratioPercent = this.props.ratio && (this.getRatioValue(this.props.ratio) * 100 + '%');

		return (
			<div className={classNames( 'img', this.props.additionalClasses, {'img--is-loading': this.state.isLoading}, {'img--is-hidden': !this.state.isVisible})} style={{paddingTop: ratioPercent}} media={this.props.media}>
				{this.state.isAlreadyLoaded &&
				<img style={{minWidth: this.state.minWidth}} src={url} alt={this.props.alt}
					 sizes={this.getSizes(this.props.sizes)} srcSet={this.getSrcSet(this.props.srcSet)}
					 loading={'lazy'}
					 onLoad={this.loadHandler}/>}
			</div>
		)
	}
}

Image.propTypes = {
	/**
	 * **REQUIRED:** **url of image** (default: transparent PNG to prevent 'broken image icon')
	 * @examples
	 * 		'https://images.canusa.de/img/regionen/bahamas/nassau/allgemein/17katalog-bahamas-nassau-haengematte.jpg'
	 *
	 */
	url: PropTypes.string.isRequired,

	/**
	 * alternative text show image could not load and it is also important for accessibility and seo
	 */
	alt: PropTypes.string,

	/**
	 *
	 */
	media: PropTypes.string,

	/**
	 * @typedef {Object}        Ratio
	 *
	 * @property {Number}        width
	 * @property {Number}        height
	 */
	ratio: PropTypes.exact({
		width: PropTypes.number.isRequired,
		height: PropTypes.number.isRequired
	}),

	/**
	 * @typedef  {Object}        SrcSet
	 *
	 * @property {String}        [ultraWideDesktop]  	- url of image for ultra wide desktop breakpoint
	 * @property {String}        [wideDesktop]    		- url of image for wide desktop breakpoint
	 * @property {String}        [desktop]           	- url of image for desktop breakpoint
	 * @property {String}        [tablet]        		- url of image for tablet breakpoint
	 * @property {String}        [mobile]        		- url of image for mobile breakpoint

	 */
	srcSet: PropTypes.shape({
		ultraWideDesktop: PropTypes.string,
		wideDesktop: PropTypes.string,
		desktop: PropTypes.string,
		tablet: PropTypes.string,
		mobile: PropTypes.string,
	}),

	/**
	 * @typedef  {Object}        Sizes
	 *
	 * @property {String}        [ultraWideDesktop]    	- size for ultra wide desktop breakpoint
	 * @property {String}        [wideDesktop]        	- size for wide desktop breakpoint
	 * @property {String}        [desktop]            	- size for desktop breakpoint
	 * @property {String}        [tablet]            	- size for tablet breakpoint
	 * @property {String}        default            	- default size
	 */
	sizes: PropTypes.shape({
		ultraWideDesktop: PropTypes.string,
		wideDesktop: PropTypes.string,
		desktop: PropTypes.string,
		tablet: PropTypes.string,
		default: PropTypes.string.isRequired,
	}),

	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.
	 */
	additionalClasses: PropTypes.string,
};

Image.defaultProps = {
	url: transparentPNG_1x1,

};

class ImageVO {

	constructor() {
		this._url = null;
		this._srcSet = null;
		this._alt = null;
		this._ratio = null;
		this._media = null;
		this._sizes = null;
	}

	get url() {
		return this._url;
	}

	set url(value) {
		this._url = value;
	}

	get srcSet() {
		return this._srcSet;
	}

	set srcSet(value) {
		this._srcSet = value;
	}

	get alt() {
		return this._alt;
	}

	set alt(value) {
		this._alt = value;
	}

	get ratio() {
		return this._ratio;
	}

	set ratio(value) {
		this._ratio = value;
	}

	get media() {
		return this._media;
	}

	set media(value) {
		this._media = value;
	}

	get sizes() {
		return this._sizes;
	}

	set sizes(value) {
		this._sizes = value;
	}
}

export {
	Image as default,
	Image,
	ImageVO
}
