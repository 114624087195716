import React, {Component} from 'react';

import './VideoVoting.scss'
import {withDataProvider} from "../../../hoc/WithDataprovider";
import {VideoTeaser} from "../../molecules/video-teaser/VideoTeaser";
import {StorageManager} from "../../../vendor/utils/StorageManager";
import ReactMarkdown from "react-markdown";
import {HumanizedText} from "../../atoms/humanizedText/HumanizedText";
import {VotingBar} from "../../molecules/voting-bar/VotingBar";

class VideoVoting extends Component {

	addHumanizedAnimation(text) {
		// special texthandling for Hackathon-Label
		if (text && text.indexOf('VIELEN') !== -1) {
			text = text.replace('VIELEN', '');
			text = <><HumanizedText overlayedText={'VIELEN'} suffixText={text} type={"stroke"} tag={"h2"}
															additionalClasses={'video-voting__headline'}/>
			</>;
		}

		return text
	}


	renderVideoVotings() {
		return this.props.list.map((item, key) => {
			return (
				<div className={'video-voting-teaser'} key={key}>
					<div className={'video-voting-teaser__votes'}></div>
					<div className={'video-voting-teaser__headline'}>
						<ReactMarkdown>
							{item.headline}
						</ReactMarkdown>

						{item.subline &&
						<div className={'video-voting-teaser__subline'}>
							<ReactMarkdown>
								{item.subline}
							</ReactMarkdown>
						</div>}
					</div>

					<div className={'video-voting-teaser__copy'}>{item.copy}
					</div>
					<div className={"video-voting-teaser__video-wrapper"}>
						<VideoTeaser additionalClasses={"video-voting-teaser__video"} {...item}/>
					</div>
				</div>
			)
		})
	}


	render() {
		return <section className={'video-voting'} id={this.props.anchor}>
			<div className={'container'}>
				<div className={'video-voting__content-wrapper'}>
					{Boolean(this.props.headline) && this.addHumanizedAnimation(this.props.headline)}
					{Boolean(this.props.copy) && <p className={'video-voting__copy'}>{this.props.copy}</p>}
				</div>
				<div className={'video-voting__teasers'}>
					{this.renderVideoVotings()}
				</div>

			</div>
		</section>;
	}
}

VideoVoting.defaultProps = {
	id: '',
	headline: "VIELEN DANK",
	copy: "Blindtexte nennt man Texte, die bei der Produktion von Publikationen oder Webseiten als Platzhalter für spätere Inhalte stehen, wenn der eigentliche Text noch nicht vorhanden ist. Sie werden auch als Dummy-, Füll- oder Platzhaltertexte bezeichnet. Mitunter sollen Komponisten von Liedern Blindtexte beim Komponieren von Melodien benutzen und diese vor dem Dichten des Liedtextes singen. Bereits seit dem 16. Jahrhundert dürften Blindtexte bei Setzern üblich gewesen sein.",
	maxVotes: 454,
	list: []
};

const VideoVotingWithDataProvider = withDataProvider(VideoVoting, {
	endpoint: 'video-voting'
});

export {
	VideoVotingWithDataProvider as default,
	VideoVotingWithDataProvider,
	VideoVoting
}


