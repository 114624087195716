import React, {Component} from 'react';

import './FaqTeaser.scss'
import PropTypes from "prop-types";
import classNames from "classnames";
import {ButtonInternal} from "../../atoms/button/ButtonInternal";

class FaqTeaser extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const classes = classNames(
			'faq-teaser',
			this.props.additionalClasses
		);

		return <section className={classes} id={this.props.anchor}>
			<div className={'faq-teaser__content-wrapper'}>
				<div className={'faq-teaser__headline'}>{this.props.headline}</div>
				<ButtonInternal linkPath={'/faq'} label={'Hier gehts zu den FAQ'}/>
			</div>
		</section>;
	}
}

FaqTeaser.defaultProps = {
	headline: "Noch Fragen?",
};

FaqTeaser.propTypes = {
	headline: PropTypes.string.isRequired,
}

export {
	FaqTeaser as default,
	FaqTeaser
}


