import React, {Component} from 'react';
import Image from '../../atoms/image/Image'

import './Community.scss'
import {SubscriptionButton} from "../../atoms/subscription-button/SubscriptionButton";
import {HumanizedText} from "../../atoms/humanizedText/HumanizedText";

class Community extends Component {
	constructor(props) {
		super(props);
	}

	render() {

		const imageSizes = {
			default: '4rem',
			desktop: '50vw',
			tablet: '75vw'
		};

		const ratio = {width: 4, height: 3}

		return (
			<section className={'community'} id={this.props.anchor}>
				<div className={'container'}>
					<div className={'community__content-wrapper'}>

						<div className={'h2 community__headline'}>
							<HumanizedText type={'teamUp'} overlayedText={'TEAM UP'}
														 additionalClasses={'gradient-gold community__teamup-animation'}/>&nbsp;mit Menschen aus
							der Community
						</div>
						<div className={'community__introduction inter'}>
							Am Ende von NACHTDENKEN sollen realisierbare Konzepte stehen, die dem Nachtleben nachhaltig weiterhelfen.
							Um das zu gewährleisten, stehen den ganzen Denkathon über Menschen, wie Club-/Barbetreiber:innen,
							Veranstalter:innen, Aktivist:innen und Szenekenner:innen, zur Seite. Entsprechende Expert:innen bilden
							auch die spätere Jury, die eure Konzepte bewerten und prämieren.
						</div>
						<SubscriptionButton subscriptionLink={this.props.subscriptionLink}
																label={'Jetzt anmelden'}
																additionalClasses={'community__button'}
																appearance={"primary"}
						/>

					</div>

					<div className={"community__grid"}>
						{this.props.community.map((item, index) => (
							<div className={"community__item"} key={index}>
								<Image
									srcSet={item.image}
									alt={item.firstname + " " + item.lastname}
									additionalClasses={'community__image'}
									ratio={ratio}
									sizes={imageSizes}
								/>
								<div className={'community__item-info'}>
									{item.info}
								</div>
								<div className={'h3 community__item-name'}>
									<span>{item.firstname} </span>
									<span className={"h3 community__last-name gradient-gold"}>
										{item.lastname}
									</span>
								</div>
								<div className={'community__statement handwriting'}>
									{item.statement}
								</div>
							</div>
						))}
					</div>
				</div>
			</section>
		)
	}
}

Community.defaultProps = {
	community: [
		{
			"firstname": "Pamela",
			"lastname": "Schobeß",
			"info": "Clubbetreiberin „Gretchen Berlin“",
			"statement": "„Clubkultur braucht \"analoge\" Nähe.“",
			"image": {
				desktop: '/images/pamela-schobers.jpg',
			}
		},
		{
			"firstname": "Andi",
			"lastname": "Till",
			"info": "Gastronom & Gründer",
			"statement": "„Wenn wir nicht bald anfangen, uns zu verändern, wird sich nichts bewegen!“",
			"image": {
				desktop: '/images/andi-till.jpg',
			}
		},
		{
			"lastname": "Megaloh",
			"info": "Rapper",
			"statement": "“Unsere Kultur ist Nahrung für die Seele - wir müssen jetzt Wege finden, wie wir sie ins neue Zeitalter transportieren”",
			"image": {
				desktop: '/images/megaloh.jpg',
			}
		},
		{
			"lastname": "Dixon",
			"info": "DJ",
			"statement": "“Die Nacht wird häufig auf Party reduziert. Dabei geht’s um so viel mehr.”",
			"image": {
				desktop: '/images/dixon.jpg',
			}
		},
		{
			"firstname": "David",
			"lastname": "Süss",
			"info": "Kulturveranstalter & Politiker",
			"statement": "“Corona schaffen wir nur zusammen, mit wirklicher, gelebter Solidarität”",
			"image": {
				desktop: '/images/david-suess.jpg',
			}
		},
		{
			"firstname": "Kathleen",
			"lastname": "Schied",
			"info": "Head of Marketing Jägermeister DE",
			"statement": "„Nachtkultur wird gemeinschaftlich gelebt, gestaltet und gerettet.“",
			"image": {
				desktop: '/images/kathleen-schied.jpg',
			}
		},
	]
};

export {
	Community as default,
	Community
}


