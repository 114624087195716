import React, {Component} from 'react';

import './Stage.scss'
import {HumanizedText} from "../../atoms/humanizedText/HumanizedText";
import {EyeCatcher} from "../../atoms/eye-catcher/EyeCatcher";
import {Countdown} from "../../atoms/countdown/Countdown";
import {withDataProvider} from "../../../hoc/WithDataprovider";
import {SubscriptionButton} from "../../atoms/subscription-button/SubscriptionButton";
import ReactMarkdown from "react-markdown";
import MobileShare from "../../atoms/share-button/ShareButton";

class Stage extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return <section className={'stage'} id={this.props.anchor}>
			<div className={'container'}>

				<HumanizedText overlayedText={'HACK'} suffixText={'ATHON'} type={'thinkStroke'} delay={800} tag={'h1'}/>

				<div className={'stage__column-wrapper'}>
					<div className={'stage__column stage__column--left'}>

						{this.props.countdownHeadline &&
						<div className={'stage__lead h2 hyphenate'}>
							<ReactMarkdown>
								{this.props.countdownHeadline}
							</ReactMarkdown>
						</div>
						}

						{(this.props.phase !== 'post' || !this.props.phase !== "realization") &&
						<Countdown phase={this.props.phase} date={this.props.endDate}/>}

						<div className={'stage__button-container'}>
							{this.props.subscriptionLink &&
								<span className={"stage__subscription-button"}>
									<SubscriptionButton subscriptionLink={this.props.subscriptionLink}/>
								</span>
							}
						</div>
						{(this.props.phase === 'subscription' || this.props.phase === 'thinking') &&
						<div className={'stage__copy'}>
							<p>
								Die Nacht bringt uns zusammen, schenkt uns Momente, die wir nie wieder vergessen und lässt uns
								unsere Kreativität und Individualität ausleben. Die Nacht gibt uns das Gefühl, dass alles möglich ist.
							</p>
							<p className={'stage__copy--bold'}>
								Wir brauchen das Nachtleben. Doch aktuell braucht es uns dringender.
							</p>
							<p>
								Durch die Corona-Krise, Kontaktbeschränkungen,
								Sperrstunden und Lockdowns findet Nachtkultur seit Monaten nicht statt – Institutionen und Menschen befinden sich
								in existenziellen Schwierigkeiten.
							</p>
							<p>
								NACHTDENKEN ist ein Online-Event, bei dem wir gemeinsam nach konkreten Lösungen suchen, die uns
								nicht nur ermöglichen sollen, möglichst schnell wieder zusammenkommen zu können. Darüber hinaus wollen
								wir die zukünftige Nachtkultur neu denken und formen.
							</p>
						</div>
						}
					</div>

					<div className={'stage__column stage__column--right'}>
						<EyeCatcher firstLine={'15.–17.'} secondLine={'Januar'}/>
					</div>
				</div>

			</div>
		</section>;

	}
}

Stage.defaultProps = {
	text: "This is a Stage",
	countdownHeadline: "zum Erhalt der Nachtkultur in",
	shareLink: {
		url: "/#share",
		label: "Teilen",
		additionalClasses: ""
	}
};

const StageWithDataProvider = withDataProvider(Stage, {
	endpoint: 'stage'
});

export {
	StageWithDataProvider as default,
	StageWithDataProvider,
	Stage
}


