import React, {Component} from 'react';

import './VideoVoting.scss'
import {withDataProvider} from "../../../hoc/WithDataprovider";
import {VideoTeaser} from "../../molecules/video-teaser/VideoTeaser";
import ReactMarkdown from "react-markdown";
import {HumanizedText} from "../../atoms/humanizedText/HumanizedText";
import {VotingBar} from "../../molecules/voting-bar/VotingBar";
import {StorageManager} from "../../../vendor/utils/StorageManager";
import Button from "../../atoms/button/Button";


const firstWaveItemCount = 10;


class VideoVotingWinner extends Component {


	constructor(props) {
		super(props);

		this.state = {
			showMoreItems: false
		}
		this.loadMoreVideos = this.loadMoreVideos.bind(this);
	}


	loadMoreVideos() {

		this.setState({showMoreItems: true})

	}


	addHumanizedAnimation(text) {
		// special texthandling for Hackathon-Label
		if (text && text.indexOf('VOTE FÜR') !== -1) {
			text = text.replace('VOTE FÜR', '');
			text = <><HumanizedText overlayedText={'VOTE FÜR'} suffixText={text} type={"stroke"} tag={"h2"}
															additionalClasses={'video-voting__headline'}/>
			</>;
		}

		return text
	}


	renderVotingItems(item, key) {
		return (
			<div className={'video-voting-teaser'} key={key}>
				<div className={'video-voting-teaser__votes'}>
					{(item.id && item.voteCount) &&
					<VotingBar votes={item.voteCount} totalVotes={this.props.maxVotes}
										 voted={item.id === StorageManager.getCookie('voted')}/>}
				</div>
				<div className={'video-voting-teaser__headline'}>
					{item.subline &&
					<div className={'video-voting-teaser__subline'}>
						<ReactMarkdown>
							{item.subline}
						</ReactMarkdown>
					</div>}
					<ReactMarkdown>
						{item.headline}
					</ReactMarkdown>

				</div>

				<div className={'video-voting-teaser__copy'}>{item.copy}
				</div>
				<div className={"video-voting-teaser__video-wrapper"}>
					<VideoTeaser additionalClasses={"video-voting-teaser__video"} {...item}/>
				</div>
			</div>
		)

	}


	renderVideoVotings() {
		return (this.props.list.map((item, key) => {
			if (key < firstWaveItemCount) {
				return this.renderVotingItems(item, key)
			} else {
				if (this.state.showMoreItems) {
					return this.renderVotingItems(item, key)
				}
			}
		}))
	}


	render() {
		return <section className={'video-voting-winner'} id={this.props.anchor}>
			<div className={'container'}>
				<div className={'video-voting__content-wrapper'}>
					{Boolean(this.props.headline) && this.addHumanizedAnimation(this.props.headline)}
					{Boolean(this.props.copy) && <p className={'video-voting__copy'}>{this.props.copy}</p>}
				</div>
				<div className={'video-voting__teasers'}>
					{this.renderVideoVotings()}

					{!this.state.showMoreItems &&
					<Button
						onClick={this.loadMoreVideos}
						type={"button"}
						appearance={"primary"}
						label={'Weitere Videos laden'}></Button>
					}
				</div>

			</div>
		</section>;
	}
}

VideoVotingWinner.defaultProps = {
	id: '',
	headline: null,
	copy: null,
	maxVotes: 454,
	list: []
};

const VideoVotingWinnerWithDataProvider = withDataProvider(VideoVotingWinner, {
	endpoint: 'video-voting-winner'
});

export {
	VideoVotingWinnerWithDataProvider as default,
	VideoVotingWinnerWithDataProvider,
	VideoVotingWinner
}


