import React, {Component} from 'react';
import PropTypes from "prop-types";
import {viewportUtils} from "../../../vendor/utils/ViewportUtils";
import './HumanizedText.scss'
import Animation from "./Animation";
import classNames from "classnames";
import ReactMarkdown from "react-markdown";

class HumanizedText extends Component {

	constructor(props) {
		super(props);
		this.elementRef = React.createRef();

		this.body = document.querySelector('body');

		this.handleScroll = this.handleScroll.bind(this);
		this.handleVisibleState = this.handleVisibleState.bind(this);

		this.state = {
			isPlaying: false,
			playForwards: true
		};
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
		this.handleVisibleState();
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll(e) {
		this.handleVisibleState();
	}


	handleVisibleState() {
		const isInViewport = viewportUtils.percentInViewportY(this.elementRef.current, null, -0.2, 0.2).fullInViewport;
		if (isInViewport) {
			this.setState({
				isPlaying: true,
				playForwards: true
			})
		} else {
			this.setState({
				isPlaying: true,
				playForwards: false
			})
		}
	}


	render() {
		let CustomTag = `${this.props.tag}`;

		const classes = classNames(
			'humanized-text',
			// {'dynamic-fontsize': this.props.tag === 'h1'},
			this.props.additionalClasses
		);

		return <CustomTag className={classes} ref={this.elementRef}>

			{this.props.prefixText && <span>{this.props.prefixText}</span>}
				<span className={'humanized-text__overlayed'}>{this.props.overlayedText}

					<Animation type={this.props.type} isPlaying={this.state.isPlaying} delay={this.props.delay}
										 playForwards={this.state.playForwards}/></span>
			{this.props.suffixText &&<span className={'humanized-text__markdown'}><ReactMarkdown>{this.props.suffixText}</ReactMarkdown></span>}
		</CustomTag>;

	}
}

HumanizedText.defaultProps = {
	overlayedText: '',
	prefixText: '',
	suffixText: '',
	type: 'thinkStroke',
	tag: 'div',
	delay: 0,
	additionalClasses: ''
};

HumanizedText.propTypes = {
	prefixText: PropTypes.string,
	overlayedText: PropTypes.string.isRequired,
	suffixText: PropTypes.string,
	additionalClasses: PropTypes.string,
	delay: PropTypes.number,
	type: PropTypes.oneOf(['thinkStroke', 'stroke', 'circleArrow', 'circle', 'teamUp']),
	tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'div'])
}

export {
	HumanizedText as default,
	HumanizedText
}
