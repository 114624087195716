class StorageManager {
	/**
	 * Set cookie
	 *
	 * @param name {string}
	 * @param value {string}
	 * @param expireDays {number}
	 */
	static setCookie(name, value, expireDays) {

		// Create expiring data in cookie format
		let d = new Date();
		d.setTime(d.getTime() + (expireDays * 24 * 60 * 60 * 1000));
		// Apply cookie data
		document.cookie = `${name}=${value};path=/;expires=${d.toUTCString()}`;
	}

	/**
	 * Get cookie
	 *
	 * @param name
	 * @returns {string}
	 */
	static getCookie(name) {
		name += "=";
		let cookies = document.cookie.split(';');
		for (var i = 0; i < cookies.length; i++) {
			let cookie = cookies[i];
			while (cookie.charAt(0) === ' ') {
				cookie = cookie.substring(1);
			}
			if (cookie.indexOf(name) === 0) {
				return cookie.substring(name.length, cookie.length);
			}
		}
		return undefined;
	}


	/**
	 * delete cookie
	 * @param name
	 * @param path
	 * @param domain
	 */
	static deleteCookie(name, path, domain) {
		if (this.getCookie(name)) {
			document.cookie = name + "=" +
				((path) ? ";path=" + path : "") +
				((domain) ? ";domain=" + domain : "") +
				";expires=Thu, 01 Jan 1970 00:00:01 GMT";
		}
	}
}

const storageManager = new StorageManager();

export {
	StorageManager, storageManager,
};
