import React, {Component} from 'react';
import './Select.scss'
import classNames from "classnames";
import {Icon} from "../icon/Icon";
import PropTypes from 'prop-types';
import {Tooltip} from "../tooltip/Tooltip";


class Select extends Component {

	constructor(props) {
		super(props);
		this.state = {
			value: props.initialValue,
			valid: true
		};
		this.changeHandler = this.changeHandler.bind(this);
	}

	componentDidMount() {
		this.checkState(this.state.value);
	}

	changeHandler(e) {

		const isValid = this.checkValidity(this.props.type, e.currentTarget.value, this.props.required);

		if (this.props.onChange) {
			this.props.onChange({
				valid: isValid,
				type: this.props.type,
				value: e.currentTarget.value,
				name: this.props.name,
				compareId: this.props.compareId
			});
		}

		this.setState({
			value: e.currentTarget.value,
			valid: isValid
		})
	}

	/**
	 * set validity and value into state
	 * publish to changeHandler
	 * @param value
	 */
	checkState(value) {
		const isValid = this.checkValidity(this.props.type, value, this.props.required);

		this.setState({value: value || '', valid: isValid});

		if (this.props.onChange) {
			this.props.onChange({
				valid: isValid,
				type: this.props.type,
				value: value,
				name: this.props.name,
				compareId: this.props.compareId
			});
		}
	}


	/**
	 * check if input has valid values
	 * @param type
	 * @param value
	 * @param isRequired
	 * @return {*|boolean}
	 */
	checkValidity(type, value, isRequired) {
		//just checking if it has value if its required at the moment
		let isValid = (value && value !== '') || !isRequired;

		if (this.props.validator) {
			isValid = this.props.validator(value);
		}
		return isValid;
	}


	render() {

		const displayErrorState = this.props.showError && !this.state.valid;

		const classes = classNames(
			'select',
			{
				[`select--theme-${this.props.theme}`]: this.props.theme,
				'select--small': this.props.smallSize,
				'select--full-width': this.props.fullWidth,
			},
			{'field-error': displayErrorState},
			this.props.additionalClasses,
		);



		let inputJSX =
			<div className={classes}>
				<select
					value={this.state.value}
					name={this.props.name}
					disabled={this.props.disabled}
					onChange={this.changeHandler}>
					{this.props.placeholder && <option disabled={true} value={'none'}>{this.props.placeholder}</option>}
					{this.props.options.map((option, i) => {
						return <option disabled={option.disabled || this.props.readOnly} key={i}
									   value={option.value}>{option.label}</option>;
					})}
					}
				</select>
				{this.props.label &&
				<label className="select__label">{this.props.label}</label>}
				<div className="select__icon-wrapper">
					<Icon additionalClasses={'select__icon'} name={'arrow-down'}/>
				</div>
			</div>;

		let renderContent = null;

		if (this.props.errorMessage) {
			renderContent = <Tooltip
				content={this.props.errorMessage}
				showDefault={false}
				inactive={!displayErrorState}
				color={'important'}
				direction={this.props.tooltipDirection}
				parentContainerSelector={this.props.parentContainerSelector}
			>

				{inputJSX}
			</Tooltip>
		} else {
			renderContent = inputJSX;
		}

		return (
			<>
				{renderContent}
			</>
		);
	}
}

Select.propTypes = {
	/**
	 * sets all options to disabled
	 */
	readOnly: PropTypes.bool,

	/**
	 * disables select
	 */
	disabled: PropTypes.bool,

	/**
	 * sets css to have smaller top and bottom padding
	 */
	smallSize: PropTypes.bool,

	/**
	 * sets css to have 100% width
	 */
	fullWidth: PropTypes.bool,

	/**
	 * sets css to use a different theme
	 */
	theme: PropTypes.oneOf(['dark']),

	/**
	 * value select should have when initialized. Must be value of one of the options
	 */
	initialValue: PropTypes.string,

	/**
	 * Defines a name for the drop-down list
	 */
	name: PropTypes.string,

	/**
	 * sets label
	 */
	label: PropTypes.string.isRequired,

	/**
	 * if placeholder text is set a default option will be added with value 'none'
	 */
	placeholder: PropTypes.string,

	/**
	 * add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.
	 */
	additionalClasses: PropTypes.string,

	/**
	 * array of options-objects
	 */
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string.required,
			label: PropTypes.string.isRequired,
			disabled: PropTypes.bool
		})
	).isRequired,

	/**
	 * changeHandler to register on value changes of select
	 */
	onChange: PropTypes.func,

};

Select.defaultProps = {
	initialValue: 'none',
	readOnly: false,
	disabled: false,
};

export {
	Select as default,
	Select
}
