import React, {Component} from 'react';

import {StorageManager} from "../../../vendor/utils/StorageManager";
import './VideoTeaser.scss'
import {Icon} from "../../atoms/icon/Icon";
import {getYoutubeIdFromUrl} from "../../../vendor/utils/Utils";
import {Modal} from "../../atoms/modal/Modal";
import {Image} from "../../atoms/image/Image";
import classNames from "classnames";
import {Button} from "../../atoms/button/Button";

class VideoTeaser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			blockYoutube: true
		};

		this.clickHandler = this.clickHandler.bind(this);
		this.validateCookies = this.validateCookies.bind(this);
		this.rewriteCookie = this.rewriteCookie.bind(this);
	}


	clickHandler() {

		this.validateCookies();

		this.showModal(true);
	}

	validateCookies() {

		const cookie = StorageManager.getCookie('cookiefirst-consent');
		if (cookie) {

			this.cookieReference = cookie;
			const cookieObj = JSON.parse(decodeURI(cookie).split('%3A').join(':').split('%2C').join(','));

			if (!cookieObj.advertising) {
				this.setState({blockYoutube: true})
			} else {
				this.setState({blockYoutube: false})
			}
		}
	}

	rewriteCookie() {
		window.CookieFirst.acceptCategory("advertising");
		this.setState({blockYoutube: false})
	}

	showModal(show) {
		this.setState({
			showModal: show
		});
	}

	render() {
		const classes = classNames(
			'video-teaser',
			'round-border',
			this.props.additionalClasses
		);
		return <div className={classes} onClick={this.clickHandler}>
			<div className={"video-teaser__wrapper"}>
				<Image additionalClasses={"video-teaser__image"} url={this.props.img}/>
				<Icon additionalClasses={'video-teaser__play'} name={'play-large'}/>
			</div>
			{this.state.showModal &&
			<Modal onClose={() => this.showModal(false)}
						 size={"big"}>
				{this.state.blockYoutube ?
					<div className={'youtube-blocker'}>
						<h3 className={'youtube-blocker__headline'}>Du hast Dich entschieden keine Cookies zuzulassen.</h3>
						<p className={'youtube-blocker__copy'}>Wenn du diese <span
							className={'highlight'}>Marketing Cookies</span> zulassen möchtest, dann kannst Du das Video trotzdem
							sehen.</p>
						<Button onClick={this.rewriteCookie} label={'Marketing-Cookies zulassen und Video ansehen'}></Button>
					</div>
					:
					<div
						className="video"
						style={{
							position: "relative",
							paddingBottom: "56.25%" /* 16:9 */,
						}}
					>
						<iframe
							allowFullScreen={"allowFullScreen"}
							allow={"autoplay"}
							style={{
								position: "absolute",
								top: 0,
								left: 0,
								width: "100%",
								height: "100%",
							}}
							src={`https://www.youtube.com/embed/${getYoutubeIdFromUrl(this.props.videoUrl)}?autoplay=1`}
							frameBorder="0"
						/>
					</div>
				}
			</Modal>
			}
		</div>;
	}
}

VideoTeaser.defaultProps = {
	img: "",
	videoUrl: ""

};

export {
	VideoTeaser as default,
	VideoTeaser
}


