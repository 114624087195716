import React from 'react';

import './CustomHeadline.scss'

const CustomHeadline = (props) => {
	return <section className={'custom-text-headline'} id={props.anchor}>
		<div className={'container'}>

			<div className={'custom-text-headline__content-wrapper'}>

				<div className={'h2 custom-text-headline__headline'}>
					<span className={'highlight'}>48H,</span> FÜR DIE EUCH DAS <span
					className={'highlight'}>NACHTLEBEN</span> UNENDLICH DANKBAR SEIN WIRD.

				</div>
			</div>
		</div>
	</section>;

}
export {
	CustomHeadline as default,
	CustomHeadline
}


