import React, {useRef, useEffect, useState} from 'react';
import {getSupportedDateFormat} from "../../../vendor/utils/DateUtils";
import './Countdown.scss'
import {phaseProvider} from "../../../hoc/PhaseProvider";

const Countdown = (props) => {

	if (props.phase === "post" || props.phase === "realization" ||  props.phase === "thinking" ||  props.phase === "pre-thinking") {
		return null;
	}
	const countdownDate = new Date(getSupportedDateFormat(props.date)).getTime();
	const startDate = getDateDiff(countdownDate);
	const [timerDays, setTimerDays] = useState(startDate.days);
	const [timerHours, setTimerHours] = useState(startDate.hours);
	const [timerMinutes, setTimerMinutes] = useState(startDate.minutes);

	let interval = useRef(null);

	function posValue(value) {
		return value <= 0 ? 0 : value;
	}

	function getDateDiff(date) {
		const now = new Date().getTime();
		const distance = date - now;
		const days = Math.floor(distance / (1000 * 60 * 60 * 24));
		const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

		return {
			"days": ("00" + posValue(days)).substr(-2),
			"hours": ("00" + posValue(hours)).substr(-2),
			"minutes": ("00" + posValue(minutes)).substr(-2),
			"timeReached": (distance <= 0)
		};
	}
	const startTimer = () => {
		interval = setInterval(() => {

			const diff = getDateDiff(countdownDate);

			if (diff.timeReached) {
				clearInterval(interval.current);
				phaseProvider.overwritePhase();
			} else {
				setTimerDays(diff.days);
				setTimerHours(diff.hours);
				setTimerMinutes(diff.minutes);
			}
		}, 1000)
	};

	useEffect(() => {
		startTimer();
		return () => {
			clearInterval(interval.current);
		};
	}, [timerMinutes])

	return <div className={'countdown'}>
		<div className={'countdown__column-wrapper'}>
			<div className={'countdown__column'}>
				<span className={'countdown__value'}>{timerDays}</span>
				<span className={'countdown__label'}>Tagen</span>
			</div>
			<div className={'countdown__column'}>
				<span className={'countdown__value countdown__value--beige'}>_</span>
				<span className={'countdown__label'}></span>
			</div>
			<div className={'countdown__column'}>
				<span className={'countdown__value'}>{timerHours}</span>
				<span className={'countdown__label'}>Stunden</span>
			</div>
			<div className={'countdown__column'}>
				<span className={'countdown__value countdown__value--beige'}>:</span>
				<span className={'countdown__label'}></span>
			</div>
			<div className={'countdown__column'}>
				<span className={'countdown__value'}>{timerMinutes}</span>
				<span className={'countdown__label'}>Minuten</span>
			</div>
		</div>
	</div>;
};

export {
	Countdown as default,
	Countdown
}
