import React from 'react';

import './CustomHeadline.scss'

const LiveEventHeadline = (props) => {
	return <section className={'custom-text-headline'} id={props.anchor}>
		<div className={'container'}>

			<div className={'custom-text-headline__content-wrapper'}>

				<div className={'h2 custom-text-headline__headline'}>
					DER DENKATHON STARTET UM 17 UHR. <br />
					<span className={'highlight'}>MELDET EUCH AN.</span>
				</div>
			</div>
		</div>
	</section>;

}
export {
	LiveEventHeadline as default,
	LiveEventHeadline
}


