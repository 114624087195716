import React, {Component} from 'react';

import './TextPage.scss'

const TextPage = (props) => {
	return (
		<section className={'text-page'}>
			<div className={'container'}>
				<div className={'text-page__content'}>
					{props.children}
				</div>
			</div>
		</section>
	)
}

export {
	TextPage as default,
	TextPage
}


