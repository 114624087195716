//BREAKPOINTS
const ULTRA_WIDE_DESKTOP_BREAKPOINT = 1920;
const WIDE_DESKTOP_BREAKPOINT = 1440;
const DESKTOP_BREAKPOINT = 1024;
const TABLET_BREAKPOINT = 768;
const MOBILE_BREAKPOINT = 320;

const BREAKPOINTS = {
	ultraWideDesktop: ULTRA_WIDE_DESKTOP_BREAKPOINT,
	wideDesktop: WIDE_DESKTOP_BREAKPOINT,
	desktop: DESKTOP_BREAKPOINT,
	tablet: TABLET_BREAKPOINT,
	mobile: MOBILE_BREAKPOINT
};

export {
	ULTRA_WIDE_DESKTOP_BREAKPOINT,
	WIDE_DESKTOP_BREAKPOINT,
	DESKTOP_BREAKPOINT,
	TABLET_BREAKPOINT,
	MOBILE_BREAKPOINT,
	BREAKPOINTS
}
