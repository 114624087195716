import React, {Component} from 'react';

import './Challenges.scss'
import {withDataProvider} from "../../../hoc/WithDataprovider";
import {HumanizedText} from "../../atoms/humanizedText/HumanizedText";
import {Icon, ICON_SIZE} from "../../atoms/icon/Icon";
import {SubscriptionButton} from "../../atoms/subscription-button/SubscriptionButton";

class Challenges extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<section className={'challenges'} id={this.props.anchor}>
				<div className={'container'}>
					<div className={'challenges__head'}>
						<div className={'challenges__headline h2'}>
							Darum <span className={'challenges__headline--orange'}>ging</span> es:
						</div>
						<div className={'challenges__info inter'}>
							<HumanizedText overlayedText={'Die Liste '}
														 tag={'div'}
														 type={"circleArrow"}
														 suffixText={' an Herausforderungen war lang. Wir hatten uns auf fünf zentrale Fragen konzentriert, um effektiv an Lösungen zu arbeiten. Jede Challenge war in sogenannten Clubs zugeordnet. In diesen Spaces konnten sich alle Teilnehmer*innen der jeweiligen Challenge treffen, austauschen und die Nacht neu denken.\n'}
							/>
						</div>
						<SubscriptionButton subscriptionLink={this.props.subscriptionLink} additionalClasses={'prices__btn'}/>
					</div>
					<div className={'challenges__challenge'}>
						<div className={'challenges__column--left'}>
							<Icon additionalClasses={'challenges__icon'} name={"challenge_1"} size={ICON_SIZE.XL}/>
						</div>
						<div className={'challenges__column--right'}>
							<div className={'challenges__challenge__label h3'}>
								Challenge 1:<br /> Dance Club</div>
							<hr className={'challenges__hr'} />
							<div className={'challenges__challenge__info'}>Wie können wir Night&shy;life-Loc&shy;at&shy;ions
								mit Hilfe neuer Techno&shy;lo&shy;gien und innovativer Design&shy;an&shy;sätze
								wie&shy;der&shy;er&shy;öffnen?
							</div>
						</div>
					</div>
					<div className={'challenges__challenge'}>
						<div className={'challenges__column--left'}>
							<Icon additionalClasses={'challenges__icon'} name={"challenge_2"} size={ICON_SIZE.XL}/>
						</div>
						<div className={'challenges__column--right'}>
							<div className={'challenges__challenge__label h3'}>Challenge 2: <br /> Social Club</div>
							<hr className={'challenges__hr'} />
							<div className={'challenges__challenge__info'}>Auf welche Art und Weise können wir trotz
								Social-Dis&shy;tanc&shy;ing unser soziales Mit&shy;ein&shy;ander auf&shy;recht&shy;er&shy;halten?
							</div>
						</div>
					</div>
					<div className={'challenges__challenge'}>
						<div className={'challenges__column--left'}>
							<Icon additionalClasses={'challenges__icon'} name={"challenge_3"} size={ICON_SIZE.XL}/>
						</div>
						<div className={'challenges__column--right'}>
							<div className={'challenges__challenge__label h3'}>Challenge 3: <br /> Culture Club</div>
							<hr className={'challenges__hr'} />
							<div className={'challenges__challenge__info'}>Wie entdecken wir wei&shy;ter&shy;hin
								neue Ta&shy;len&shy;te, un&shy;ter&shy;stütz&shy;en eta&shy;blierte Künstler&shy;*innen
								und er&shy;mög&shy;lich&shy;en es ihnen, ihre Kre&shy;ati&shy;vi&shy;tät auf
								un&shy;ter&shy;schied&shy;lich&shy;sten Bühnen einem Pub&shy;li&shy;kum zu prä&shy;sen&shy;tier&shy;en?
							</div>
						</div>
					</div>
					<div className={'challenges__challenge'}>
						<div className={'challenges__column--left'}>
							<Icon additionalClasses={'challenges__icon'} name={"challenge_4"} size={ICON_SIZE.XL}/>
						</div>
						<div className={'challenges__column--right'}>
							<div className={'challenges__challenge__label h3'}>Challenge 4: <br /> Movers & Shakers</div>
							<hr className={'challenges__hr'} />
							<div className={'challenges__challenge__info'}>Auf welchen Wegen können Gas&shy;tro&shy;nom&shy;en
								trotz Lock&shy;down, Hy&shy;giene-Auf&shy;lagen und Aus&shy;geh&shy;be&shy;schränk&shy;ung&shy;en
								Umsatz ge&shy;ner&shy;ier&shy;en?
							</div>
						</div>
					</div>
					<div className={'challenges__challenge'}>
						<div className={'challenges__column--left'}>
							<Icon additionalClasses={'challenges__icon'} name={"challenge_5"} size={ICON_SIZE.XL}/>
						</div>
						<div className={'challenges__column--right'}>
							<div className={'challenges__challenge__label h3'}>Challenge 5: <br /> Daybreak Club</div>
							<hr className={'challenges__hr'} />
							<div className={'challenges__challenge__info'}>Mit&shy;hil&shy;fe welcher kre&shy;ati&shy;ven oder tech&shy;nischen Maß&shy;nahmen und Inno&shy;va&shy;tionen können Fes&shy;ti&shy;vals und Open-Airs wieder statt&shy;finden?
							</div>
						</div>
					</div>
				</div>
			</section>
		)
	}
}

Challenges.defaultProps = {
	text: "This is a Challenge"
};

const ChallengesWithDataProvider = withDataProvider(Challenges, {
	endpoint: 'challenges'
});

export {
	Challenges as default,
	Challenges
}


