import React, {Component} from 'react';
import './Button.scss'
import {Button} from "../button/Button";
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';

class ButtonInternal extends Component {

	render() {

		let {linkPath, ...passThroughProps} = this.props;

		return (
			<>
				{this.props.disabled
					?
					<Button {...passThroughProps}/>
					:
					<Link to={linkPath}>
						<Button {...passThroughProps} />
					</Link>
				}
			</>
		)
	}
}

ButtonInternal.propTypes = {
	/**
	 * **REQUIRED**: for type: **internal**
	 */
	linkPath: PropTypes.string.isRequired,

	/**
	 * OPTIONAL: disables component
	 */
	disabled: PropTypes.bool,

	/**
	 * **REQUIRED**: Label of the button
	 */
	label: PropTypes.string.isRequired,

	/**
	 * OPTIONAL: unique id of the button
	 */
	uid: PropTypes.string,

	/**
	 * OPTIONAL: button title
	 */
	title: PropTypes.string,

	/**
	 * OPTIONAL: icon-string @see 'utils/Mappings.js (iconMap)'
	 */
	icon: PropTypes.string,

	/**
	 * OPTIONAL:
	 */
	appearance: PropTypes.oneOf(['primary', 'secondary', 'important', 'main-navigation', 'dark']),

	/**
	 * OPTIONAL:
	 * @default 'full-width'
	 */
	width: PropTypes.oneOf(['full-width', 'fit', 'fixed']),

	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.*
	 */
	additionalClasses: PropTypes.string,

	/**
	 * use round borders on buttons
	 * @default: true
	 */
	useRoundBorder: PropTypes.bool
};

export {
	ButtonInternal as default,
	ButtonInternal
}
