import React, {useState, useEffect} from 'react'
import {Redirect} from 'react-router-dom';
import './AgeGate.scss'
import {HumanizedText} from "../../components/atoms/humanizedText/HumanizedText";
import {StorageManager} from "../../vendor/utils/StorageManager";
import classNames from "classnames";
import {Modal} from "../../components/atoms/modal/Modal";
import {Button} from "../../components/atoms/button/Button";
import {Link} from "../../components/atoms/link/Link";
import {Select} from "../../components/atoms/select/Select";

import countryList from './countries';

const refMap = {
	day: React.createRef(),
	month: React.createRef(),
	year: React.createRef()
}
const MAX_AGE = 120;
const AgeGate = (props) => {
	const [showModal, setShowModal] = useState(false);
	const [input, setInput] = useState({});
	const [validDay, setValidDay] = useState(true);
	const [validMonth, setValidMonth] = useState(true);
	const [validYear, setValidYear] = useState(true);
	const [age, setAge] = useState('');
	const [allowedAge, setAllowedAge] = useState(18);


	const handleSelectChange = (event) => {
		const age = getAgeFromCountryList(event.value);

		setInput({day: '', month: '', year: ''});

		if (age) {
			setAllowedAge(age);
		}
	}

	const handleInputChange = (event) => {
		const maxValue = event.target.name !== 'year' ? 2 : 4;
		const value = event.target.value.replace(/\D/, '').substr(0, maxValue);

		if (value.length === maxValue) {
			let step = stepList.indexOf(event.currentTarget.name)
			step++;

			//Focus next input field
			if (refMap[stepList[step]]) {
				refMap[stepList[step]].current.focus();
			}
		}

		setInput({
			...input,
			[event.currentTarget.name]: value
		})
	}

	const handleBlurChange = (event) => {
		if (event.target.value.length === 1) {
			event.target.value = "0" + event.target.value
			handleInputChange(event);
		}
	}

	const handleFocusChange = (event) => {
		event.currentTarget.select();
	}

	const getAgeFromCountryList = (value) => {
		const obj = countryList.filter(country => country.value === value);

		if (obj.length > 0) {
			return obj[0].age || 300;
		}
	}

	useEffect((e) => {
			if (input.day?.length === 2) {
				setValidDay(validateDay(input.day, input.month))
			}
			if (input.month?.length === 2) {
				setValidMonth(validateMonth(input.month))
			}
			if (input.year?.length === 4) {
				setValidYear(validateYear(input.year))
			}
		}, [input]
	);


	useEffect((e) => {

		if (input.day?.length === 2 && input.month?.length === 2 && input.year?.length === 4) {
			const dateOfBirth = new Date(input.year, input.month - 1, input.day);
			const userAge = calculateAge(dateOfBirth);
			setAge(userAge);

			if (validDay && validMonth && validYear) {
				cookieHandler(userAge);
				props.updateCallback();
			} else {
				setShowModal(true);
			}

		}
	}, [validDay, validMonth, validYear, input, allowedAge]);

	/**
	 * set cookies based on Age
	 * @param userAge
	 */
	const cookieHandler = (userAge) => {
		if (userAge >= allowedAge && userAge <= MAX_AGE) {

			window.gtag('event', 'agegate_passed', {
				'event_category' : 'engagement',
				'event_label' : 'agegate passed',
				'age': userAge,
				'non_interaction': true});


			StorageManager.setCookie('isAged', true, 30);
		}
	}

	const removeFocus = () => {
		setTimeout(() => {
			for (let key in refMap) {
				if (refMap[key].current) {
					refMap[key].current.blur();
				}
			}
		}, 100)
	}

	const analyseAge = (age) => {
		if (age) {
			if (age < allowedAge) {
				return (
					showModal &&
					<Modal onClose={() => setShowModal(false)} size={"medium"} theme={"dark"}>
						{removeFocus()}
						{showTooYoung()}
						<Button label={'OK'}
								appearance={'primary'}
								additionalClasses={'agegate__modal-btn'}
								onClick={() => setShowModal(false)}
						/>
					</Modal>
				)
			} else if (age > MAX_AGE) {
				return (
					showModal &&
					<Modal onClose={() => setShowModal(false)} size={"medium"} theme={"dark"}>
						{removeFocus()}
						{showTooOld()}
						<Button label={'OK'}
								appearance={'primary'}
								additionalClasses={'agegate__modal-btn'}
								onClick={() => setShowModal(false)}
						/>
					</Modal>
				)
			} else {
				return showSuccess(age)
			}
		}
	}

	/**
	 * calculate age in years
	 * @param dateOfBirth
	 * @returns {number}
	 */
	const calculateAge = (dateOfBirth) => {
		let diffInMs = Date.now() - dateOfBirth.getTime() - 8.64e7;
		let ageDelta = new Date(diffInMs);
		return Math.abs(ageDelta.getUTCFullYear() - 1970);
	}

	/**
	 * Validate Day (depending on month)
	 * @param day
	 * @param month
	 * @returns {boolean}
	 */
	const validateDay = (day, month) => {
		let days = 31;

		if (month) {
			switch (month.toString()) {
				case '04':
				case '06':
				case '09':
				case '11':
					days = 30;
					break;
				case '02':
					days = 28;
					break;
				default:
					days = 31;
			}
		}

		return (day?.length > 0 && day <= days);
	}

	/**
	 * Validate Month
	 * @param month
	 * @returns {boolean}
	 */
	const validateMonth = (month) => {
		month = parseInt(month);
		return (month >= 1 && month <= 12) ? true : false;
	}

	/**
	 * Validate Year
	 * @param year
	 * @returns {boolean}
	 */
	const validateYear = (year) => {
		const y = new Date().getUTCFullYear()
		const min = y - allowedAge;
		const max = y - MAX_AGE;

		if (year && year.length < 4) {
			return false;
		}

		return (year <= min && year >= max);
	}


	const showAgeGate = () => {

		return <>
			<HumanizedText type={"stroke"}
						   overlayedText={'WILLKOMMEN'}
						   suffixText={''}
						   additionalClasses={'agegate__headline'}
						   tag={"h2"}
						   delay={800}
			/>
			<div className={'agegate__info-text'}>
				Um diese Websites besuchen zu können, musst du mindestens {allowedAge} Jahre alt sein.
			</div>


			<div className={'agegate__label-text'}>
				Bitte gib Dein Geburtsdatum ein:
			</div>

			<div className={'agegate__date'}>

				<div className={'agegate__row'}>
					<div className={'agegate__input'}>
						<input placeholder={'TT'}
							   type={'tel'}
							   name={'day'}
							   className={'agegate__input'}
							   required={true}
							   onChange={handleInputChange}
							   onFocus={handleFocusChange}
							   onBlur={handleBlurChange}
							   value={input.day || ''}
							   ref={refMap['day']}
							   autoComplete={'off'}
							   data-error={!validDay}
						/>
						<label>Tag</label>
					</div>

					<div className={'agegate__input'}>
						<input placeholder={'MM'}
							   type={'tel'}
							   name={'month'}
							   className={'agegate__input'}
							   required={true}
							   onChange={handleInputChange}
							   onFocus={handleFocusChange}
							   onBlur={handleBlurChange}
							   value={input.month || ''}
							   ref={refMap['month']}
							   autoComplete={'off'}
							   data-error={!validMonth}

						/>
						<label>Monat</label>
					</div>
				</div>

				<div className={'agegate__row'}>
					<div className={'agegate__input'}>
						<input placeholder={'JJJJ'}
							   type={'tel'}
							   name={'year'}
							   className={'agegate__input agegate__input--year'}
							   required={true}
							   onChange={handleInputChange}
							   onFocus={handleFocusChange}
							   value={input.year || ''}
							   ref={refMap['year']}
							   autoComplete={'off'}
							   data-error={!validYear}
						/>
						<label>Jahr</label>
					</div>
				</div>

			</div>
			<Select label={'Land'}
					options={countryList}
					fullWidth={false}
					name={'country'}
					additionalClasses={'agegate__select'}
					onChange={handleSelectChange}
					initialValue={'DE'}
			/>

		</>

	}

	const showSuccess = (age) => {

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({'event':'agegate-passed', 'label':age});
		return <>

			<Redirect to={'/'}/>
		</>
	}

	const showTooYoung = () => {
		return <>
			<div className={'h3 agegate__modal-text'}>TUT UNS LEID, DU BIST NOCH NICHT {allowedAge}. ABER DAS WEISST DU SELBST</div>
			<p>Um unsere Jägermeister Websites besuchen zu können musst du mindestens {allowedAge} Jahre alt sein. Mehr Informationen
				dazu gibt´s in unserem <Link
					to={'https://www.jagermeister.com/de-DE/impressum/marketing-kodex'} target={'_blank'}
					label={'Jägermeister Marketing-Kodex'}/> und unter <Link
					to={'https://www.massvoll-geniessen.de/'} target={'_blank'} label={'Massvoll Geniessen'}/>!
			</p>
		</>

	}

	const showTooOld = () => {
		return <>
			<div className={'h3 agegate__modal-text'}>MANN BIST DU ALT! RESPEKT.</div>
			<p>Dies ist nicht die Website für Weltrekorde, sondern für Jägermeister, einen über hundert Jahre alten
				Kräuterlikör, den Du altersmäßig noch zu überholen scheinst.Versuch es gern erneut oder lies den <Link
					to={'https://www.jagermeister.com/de-DE/impressum/marketing-kodex'} target={'_blank'}
					label={'JÄGERMEISTER MARKETING-KODEX'}/> für
				weitere Informationen.</p>
		</>
	}

	const classes = classNames(
		'agegate',
	);

	return (
		<>
			<div className={classes}>
				<div className={'agegate__container'}>
					{analyseAge(age)}
					{showAgeGate()}
				</div>
			</div>
		</>
	)
}

const stepList = ['day', 'month', 'year']


Object.filter = (obj, predicate) =>
	Object.keys(obj)
	.filter(key => predicate(obj[key]))
	.reduce((res, key) => (res[key] = obj[key], res), {});

export {
	AgeGate as default,
	AgeGate
}


