import React from 'react';

import './DynamicHeadlineCopy.scss'
import ReactMarkdown from "react-markdown";
import {withDataProvider} from "../../../hoc/WithDataprovider";

const DynamicHeadlineCopy = (props) => {

	return <section className={'dynamic-headline-copy'} id={props.anchor}>
		<div className={'container'}>

			<div className={'dynamic-headline-copy__content-wrapper'}>
				{props.headline && <div className={'h2 dynamic-headline-copy__headline'}>{props.headline}</div>}
				{props.copy && <div className={'dynamic-headline-copy__copy'}><ReactMarkdown>{props.copy}</ReactMarkdown></div>}
			</div>
		</div>
	</section>;


}
const DynamicHeadlineCopyWithDataProvider = withDataProvider(DynamicHeadlineCopy, {
	endpoint: 'dynamic-headline-copy'
});


export {
	DynamicHeadlineCopyWithDataProvider as default,
	DynamicHeadlineCopyWithDataProvider,
	DynamicHeadlineCopy
}


