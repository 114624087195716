import React, {useState, useEffect} from 'react';
import {isAfterDate, isBeforeDate, getSupportedDateFormat} from "../vendor/utils/DateUtils";
import {withDataProvider} from "../hoc/WithDataprovider";
import Stage from "./organisms/stage/Stage";
import {Questions} from "./organisms/questions/Questions";
import SocialmediaWall from "./organisms/socialmedia-wall/SocialmediaWall";
import VideoGallery from "./organisms/video-gallery/VideoGallery";
import VideoStream from "./organisms/video-gallery/VideoStream";
import VideoVoting from "./organisms/video-voting/VideoVoting";
import VideoVotingWinner from "./organisms/video-voting/VideoVotingWinner";
import {Challenges} from "./organisms/challenges/Challenges";
import {Community} from "./organisms/community/Community";
import {TimelineWithDataProvider} from "./organisms/timeline/Timeline";
import {FaqTeaser} from "./organisms/faq-teaser/FaqTeaser";
import Sponsors from "./organisms/sponsors/Sponsors";
import {Prices} from "./organisms/prices/Prices";
import {CustomHeadlineCopy} from "./molecules/custom-textblock/CustomHeadlineCopy";
import {CustomHeadline} from "./molecules/custom-textblock/CustomHeadline";
import DynamicHeadlineCopy from "./molecules/custom-textblock/DynamicHeadlineCopy";
import {LiveEventHeadline} from "./molecules/custom-textblock/LiveEventHeadline";

export const FACTORY_TYPE_STAGE = "stage";
export const FACTORY_TYPE_QUESTIONS = "questions";
export const FACTORY_TYPE_SOCIALMEDIAWALL = "socialmedia-wall";
export const FACTORY_TYPE_VIDEO_GALLERY = "video-gallery";
export const FACTORY_TYPE_VIDEO_STREAM = "video-stream";
export const FACTORY_TYPE_VIDEO_VOTING = "video-voting";
export const FACTORY_TYPE_VIDEO_VOTING_WINNER = "video-voting-winner";
export const FACTORY_TYPE_CHALLENGES = "challenges";
export const FACTORY_TYPE_COMMUNITY = "community";
export const FACTORY_TYPE_TIMELINE = "timeline";
export const FACTORY_TYPE_FAQ_TEASER = "faq-teaser";
export const FACTORY_TYPE_PRICES = "prices";
export const FACTORY_TYPE_SPONSORS = "sponsors";
export const FACTORY_TYPE_CUSTOM_HEADLINE_COPY = "darum_gehts";
export const FACTORY_TYPE_DYNAMIC_HEADLINE_COPY = "dynamic-headline-copy";
export const FACTORY_TYPE_CUSTOM_HEADLINE = "nachtleben_48h";
export const FACTORY_TYPE_LIVE_EVENT_HEADLINE = "denkathon_startet";

export const getComponent = (node) => {
	const type = node || {};
	switch (type) {
		case FACTORY_TYPE_STAGE:
			return Stage;
		case FACTORY_TYPE_QUESTIONS:
			return Questions;
		case FACTORY_TYPE_SOCIALMEDIAWALL:
			return SocialmediaWall;
		case FACTORY_TYPE_VIDEO_GALLERY:
			return VideoGallery;
		case FACTORY_TYPE_VIDEO_STREAM:
			return VideoStream;
		case FACTORY_TYPE_VIDEO_VOTING:
			return VideoVoting;
		case FACTORY_TYPE_VIDEO_VOTING_WINNER:
			return VideoVotingWinner;
		case FACTORY_TYPE_CHALLENGES:
			return Challenges;
		case FACTORY_TYPE_COMMUNITY:
			return Community;
		case FACTORY_TYPE_TIMELINE:
			return TimelineWithDataProvider;
		case FACTORY_TYPE_FAQ_TEASER:
			return FaqTeaser;
		case FACTORY_TYPE_PRICES:
			return Prices;
		case FACTORY_TYPE_SPONSORS:
			return Sponsors;
		case FACTORY_TYPE_CUSTOM_HEADLINE_COPY:
			return CustomHeadlineCopy;
		case FACTORY_TYPE_DYNAMIC_HEADLINE_COPY:
			return DynamicHeadlineCopy;
		case FACTORY_TYPE_CUSTOM_HEADLINE:
			return CustomHeadline;
		case FACTORY_TYPE_LIVE_EVENT_HEADLINE:
			return LiveEventHeadline;

		default:
			throw new Error(`Component "${type}" is not implemented`);
	}
};

const InnerFactory = (props) => {

	const {node, children, ...innerProps} = props;
	try {
		let Component = getComponent(node);
		return <Component {...innerProps}>{children}</Component>;
	} catch (error) {
		return <div>{error.message}</div>;
	}
};

const Factory = (props) => {
	const [modules, setModules] = useState(null);
	const [phase, setPhase] = useState(null);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [subscriptionLink, setSubscriptionLink] = useState(null);

	const currentDate = new Date();
	useEffect(() => {
		if (!modules && props.content) {
			getModules(props.content);
		}
	});

	useEffect(() => {

		if (props.getSubscriptionLinkFromFactory) {
			props.getSubscriptionLinkFromFactory(subscriptionLink);
		}
	}, [subscriptionLink]);

	useEffect(() => {
		if (props.getPhaseFromFactory) {
			props.getPhaseFromFactory(phase);
		}
	}, [phase]);


	const getModules = (pages) => {
		const currentPage = pages.find((current, index, arr) => {

			current.endDate = current.finalDate || arr[index + 1].startDate;

			if (index === arr.length - 1) {
				return true;
			}

			if (props.forcePhase) {
				return current.id === props.forcePhase;
			}

			return (isAfterDate(currentDate, new Date(getSupportedDateFormat(current.startDate))) && isBeforeDate(currentDate, new Date(getSupportedDateFormat(arr[index + 1].startDate))))
		});

		setModules(currentPage.modules);
		setPhase(currentPage.id);
		setStartDate(currentPage.startDate);
		setEndDate(currentPage.endDate);
		setSubscriptionLink(currentPage.subscriptionLink);

	};

	return (
		modules && modules.map((module, index) => <InnerFactory key={index}
																node={module.id}
																phase={phase}
																startDate={startDate}
																endDate={endDate}
																subscriptionLink={subscriptionLink}
																anchor={module.anchor}>{props.children}</InnerFactory>)
	);

};

Factory.defaultProps = {
	forcePhase: null,
	getPhaseFromFactory: null
};

const FactoryWithDataProvider = withDataProvider(Factory, {
	endpoint: 'pages'
});

export {
	FactoryWithDataProvider as default,
	FactoryWithDataProvider,
	Factory
}
