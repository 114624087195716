import React, {Component} from 'react';
import './SocialMediaWall.scss'
import {withDataProvider} from "../../../hoc/WithDataprovider";
import {viewportUtils} from "../../../vendor/utils/ViewportUtils";
import classNames from "classnames";

class SocialmediaWall extends Component {

	constructor(props) {
		super(props);
		this.elementRef = React.createRef();

		this.state = {
			isThemeLight: false
		}
		this.handleScroll = this.handleScroll.bind(this);
		this.handleVisibleState = this.handleVisibleState.bind(this);

	}
	componentDidMount() {
		this.loadScript();
		window.addEventListener('scroll', this.handleScroll);
		this.handleVisibleState();
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll(e) {
		this.handleVisibleState();
	}

	handleVisibleState() {
		const isInViewport = viewportUtils.percentInViewportY(this.elementRef.current).percent >= 0.7 ? true : false;

		this.setState({
			isThemeLight: isInViewport
		})
	}

	loadScript() {
		let i, e, d = document, s = "script";
		i = d.createElement("script");
		i.async = 1;
		i.src = "https://assets.juicer.io/embed.js";
		e = d.getElementsByTagName(s)[0];
		e.parentNode.insertBefore(i, e);
	}


	render() {
		const classes = classNames(
			'socialmedia-wall',
			{'socialmedia-wall--theme-light': this.state.isThemeLight},
			this.props.additionalClasses
		);

		return (
			<section className={classes} ref={this.elementRef} id={this.props.anchor}>
				<div className={'container'}>
					<ul className="juicer-feed" data-feed-id={this.props.feedId} data-per={this.props.per} data-gutter={this.props.gutter}>
						<h1 className="referral">
							<a href="https://www.juicer.io">Powered by Juicer.io</a>
						</h1>
					</ul>
				</div>
			</section>
		)
	}
}

SocialmediaWall.defaultProps = {
	feedId: null,
	per:11,
	gutter:16,
	additionalClasses : ''
};

const SocialmediaWallWithDataProvider = withDataProvider(SocialmediaWall, {
	endpoint: 'sm-wall'
});

export {
	SocialmediaWallWithDataProvider as default,
	SocialmediaWallWithDataProvider,
	SocialmediaWall
}
