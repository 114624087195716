import React from 'react';

import './CustomHeadlineCopy.scss'
import {HumanizedText} from "../../atoms/humanizedText/HumanizedText";

const CustomHeadlineCopy = (props) => {
	return <section className={'custom-text-headlinecopy'} id={props.anchor}>
		<div className={'container'}>

			<div className={'custom-text-headlinecopy__content-wrapper'}>
				<HumanizedText prefixText={""} overlayedText={"Hack"} suffixText={"athon präsentiert die 10&nbsp;Fina&shy;listen"} type={"thinkStroke"} tag={"h2"}
												 additionalClasses={'custom-text-headlinecopy__headline'}/>
				<p className={'custom-text-headlinecopy__copy'}>
					{"Ihr habt den Projekten, die eurer Meinung nach am meisten zum Erhalt der Nachtkultur beitragen eure Stimmen gegeben und somit das Nachtleben unterstützt. Denn es braucht nicht nur Menschen mit guten Ideen, sondern auch die, die das Potenzial guter Ideen erkennen und vorantreiben. Das Voting wurde von der Jury bei ihrer finalen Bewertung der Ideen berücksichtigt. Wieso ist das Projekt mit den meisten Stimmen, nicht auch das Gewinnerprojekt des Denkathons? Die Jury hat jedes der folgenden Konzepte anhand von zahlreichen Kriterien, wie Umsetzbarkeit, Einzigartigkeit und Relevanz für die Rettung des Nachtlebens bewertet. Das Votings fiel in der Berechnung des Endergebnisses im Vergleich zu anderen Kriterien kleiner aus."}</p>
			</div>
		</div>
	</section>;

}
export {
	CustomHeadlineCopy as default,
	CustomHeadlineCopy
}


