import React, {useEffect, useState} from 'react';
import classNames from "classnames";
import {StorageManager} from "../vendor/utils/StorageManager";
import {phaseProvider} from "../hoc/PhaseProvider";
import './MainPage.scss'
import Factory from "../components";
import {AgeGate} from "./agegate/AgeGate";
import {Layout} from "./Layout";

//create your forceUpdate hook
function useForceUpdate(){
	const [value, setValue] = useState(0); // integer state
	return () => setValue(value => ++value); // update the state to force render
}

const isLive = () => {
	return window.location.href.indexOf('nachtdenken') > -1;
};

const MainPage = (props) => {
	// call your hook here
	const forceUpdate = useForceUpdate();

	const classes = classNames(
		'main-page',
	);

	const defaultPhase = !isLive() && props?.match?.params?.phase;
	return (
		<>
			<Layout>
				<div className={classes}>
					{StorageManager.getCookie('isAged') === 'true' ?
						<Factory forcePhase={defaultPhase}
								 getPhaseFromFactory={(phase) => {
									 phaseProvider.setPhase(phase);
								 }}
						/>
						:
						<AgeGate updateCallback={forceUpdate}/>
					}
				</div>
			</Layout>
		</>)
};

export {
	MainPage as default,
	MainPage
}
