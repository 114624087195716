import React from 'react';

import './ImprintPage.scss'
import {TextPage} from "../../components/organisms/text-page/TextPage";
import {Layout} from "../Layout";

const ImprintPage = () => {
	return (
		<Layout>
			<TextPage>
				<h1>Impressum</h1>
				<div className={'imprint-page__subline'}>Mast Jägermeister Deutschland GmbH</div>
				<div className={'imprint-page'}>
					Jägermeisterstraße 7-15< br />
					38296 Wolfenbüttel< br />
					Deutschland<br />
				</div>
				<br />
				<div>
					Sitz Wolfenbüttel<br />
					Amtsgericht Braunschweig<br />
					Handelsregister-Nr. B 206625<br />
					UST-ID.: DE312568193
				</div>


				<div className={'imprint-page__subline'}>Geschäftsführer</div>
				<div className={'imprint-page__item'}>
					Torsten Römsch<br/>
					Christian Koch
				</div>
				<div className={'imprint-page__subline'}>Inhaltliche Verwantwortung</div>
				<div className={'imprint-page__item'}>
					Katrin Mirtschink<br />
					Jägermeisterstraße 7-15<br />
					38296 Wolfenbüttel Deutschland
				</div>
				<div>Telefon: +49 5331 81-0<br />
					E-Mail: <a href={"mailto:katrin.mirtschink@jaegermeister.de"}>katrin.mirtschink@jaegermeister.de</a>
				</div>

				<div className={'imprint-page__subline'}>Konzept und Design</div>
				<div>
					<a href="http://www.la-red.de" target="_blank">
						La Red
					</a>
				</div>
				<div className={'imprint-page__subline'}>Umsetzung</div>
				<div>
					<a href="http://www.p2er.de" target="_blank">
						P2ER
					</a>
				</div>
			</TextPage>
		</Layout>)
}

export {
	ImprintPage as default,
	ImprintPage
}
