import React, {Component} from 'react';

import './Timeline.scss'
import {withDataProvider} from "../../../hoc/WithDataprovider";
import {viewportUtils} from "../../../vendor/utils/ViewportUtils";
import classNames from "classnames";
import {HumanizedText} from "../../atoms/humanizedText/HumanizedText";
import {Icon, ICON_SIZE} from "../../atoms/icon/Icon";
import {SubscriptionButton} from "../../atoms/subscription-button/SubscriptionButton";
import ReactMarkdown from 'react-markdown'

class Timeline extends Component {

	/**
	 * Fetch data from db or fall back to local config
	 * @param props
	 */
	constructor(props) {
		super(props);
		this.elementRef = React.createRef();

		this.state = {
			isThemeLight: false
		}
		this.handleScroll = this.handleScroll.bind(this);
		this.handleVisibleState = this.handleVisibleState.bind(this);
		this.addHumanizedAnimation = this.addHumanizedAnimation.bind(this);

	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
		this.handleVisibleState();
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll(e) {
		this.handleVisibleState();
	}

	handleVisibleState() {
		const isInViewport = viewportUtils.percentInViewportY(this.elementRef.current).percent >= 0.7 ? true : false;

		this.setState({
			isThemeLight: isInViewport
		})
	}

	addHumanizedAnimation(text) {
		// special texthandling for Hackathon-Label
		if (text && text.indexOf('Kreieren') !== -1) {
			text = text.replace('Kreieren', '');
			text = <>
				<div className={'h2'}>{text}</div>
				<HumanizedText overlayedText={'Kreieren'} suffixText={''} type={"circle"} tag={"h2"}/>

			</>;
		}

		return text
	}

	render() {

		const classes = classNames(
			'timeline',
			{'timeline--theme-light': this.state.isThemeLight},
			this.props.additionalClasses
		);

		const itemList = Object.keys(this.props.timeline);
		const phase = this.props.phase === 'pre-thinking' ? 'thinking' : this.props.phase;

		return (
			<section className={classes} ref={this.elementRef} id={this.props.anchor}>
				<div className={'container'}>
					<div className={'timeline__head'}>
						<div className={'h2 timeline__headline'}>
							<span className={'timeline__headline--colored'}>Ablauf</span>
						</div>
						<div className={'timeline__info'}></div>
					</div>

					{itemList.map((item, key) => (

						<div className={'timeline__item'} key={item}>

							<div className={'timeline__column-left'}>
								<Icon additionalClasses={'timeline__radio'}
											name={itemList.indexOf(phase) >= key ? 'radioBtnChecked' : 'radioBtnUnchecked'}
											size={ICON_SIZE.L}/>
								<hr className={'timeline__vertical-line'}/>
							</div>
							<div className={'timeline__column-right'}>
								<div
									className={'h3 timeline__item-label'}>{this.addHumanizedAnimation(this.props.timeline[item].title)}</div>
								<div className={'timeline__item-text copy'}>
									<hr className={'timeline__horizontal-line'}/>
									{<ReactMarkdown>{this.props.timeline[item].info}</ReactMarkdown>}
								</div>

								{this.props.timeline[item].button &&
								<SubscriptionButton subscriptionLink={this.props.subscriptionLink}
																		additionalClasses={'timeline__register-btn'}/>

								}

								{this.props.timeline[item].subline &&
										<ReactMarkdown className={'timeline__item-text subline'}>{this.props.timeline[item].subline}</ReactMarkdown>
								}
							</div>
						</div>
					))}
				</div>
			</section>
		);
	}
}

Timeline.defaultProps = {
	text: "This is a Timeline",
	timeline: {
		"timeline": {
			"subscription": {
				"title": "Anmeldung",
				"info": "Die Anmeldung ist bis um **19 Uhr am 15.01.21** möglich.",
				"button": true,
				"subline": "\n\n(wer kann) **Meet’n’Greet** am **12.01.** um **19 und 21 Uhr**",
			},
			"thinking": {
				"title": "Tag 1: Kick-Off",
				"info": "**15.01.21**\n&nbsp;\nLive Opening Event.\nTalks, Musik, Teambuilding.",
				"button": false
			},
			"event": {
				"title": "Tag 2: Kreieren",
				"info": "**16.01.21**\n&nbsp;\nOnline Breakfast.\nDenken, denken, denken.\nBreak.\nSpaß haben.\nWeiter denken",
				"button": false
			},
			"post": {
				"title": "Tag 3: Präsentieren",
				"info": "**17.01.21**\n&nbsp;\nDeadlines.\nIdeeneinreichung.\nPitches.\nKurz warten\nAbschlussevent inkl. Preisverleihung.",
				"button": false
			},
			"realization": {
				"title": "Umsetzung",
				"info": "Die besten Ideen gehen in die Realisierungsprüfung und Umsetzung.",
				"button": false
			}
		}
	}
};

const TimelineWithDataProvider = withDataProvider(Timeline, {
	endpoint: 'timeline'
});

export {
	TimelineWithDataProvider as default,
	TimelineWithDataProvider,
	Timeline
}


