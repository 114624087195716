import React, {useEffect} from 'react';
import {Collapsible} from "../../components/atoms/collapsible/Collapsible";
import './FAQPage.scss';
import PropTypes from "prop-types";
import {withDataProvider} from "../../hoc/WithDataprovider";
import {Layout} from "../Layout";

function FaqPage(props) {

	function createSection(section) {
		return(
			<div className={'faq-page__section'}>
				<div className={'faq-page__section-head h3'}>{section.headline}</div>
				{section.list && section.list.map((item) => {
					return <Collapsible
						key={item.id}
						id={item.id}
						additionalClasses='faq__entry'
						headContent={<h3 key={'headline-' + item.id}><span className={'highlight'}>FRAGE:</span> {item.question}
						</h3>}
						content={item.answer}
						hasShadowOnHover={false}
						forceToggleArrow={true}
					/>
				})}
			</div>
		);
	}

	return (
		<Layout>
			<div className={'faq-page'}>
				<div className={'container'}>
					<div className={'faq-page__content-wrapper'}>
						<h2 className={'faq-page__headline'}>Frequently Asked <span className={'highlight'}>Questions</span></h2>
						{props.sections && props.sections.map((section) => {return createSection(section)})}
					</div>
				</div>
			</div>
		</Layout>
	)
}

FaqPage.defaultProps = {
	list: []
};

FaqPage.propTypes = {
	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.*
	 */
	additionalClasses: PropTypes.string,

	/**
	 * **REQUIRED**: json-formatted array of FAQ-entries:
	 *
	 * @example: [{ "id":int,
	 * 	 "question":"string",
	 * 	 "answer":"string",
	 * 	}]
	 */
	list: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		question: PropTypes.string,
		answer: PropTypes.string,
	})),
	sections: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		question: PropTypes.string,
		answer: PropTypes.string,
	}))
};

const FaqPageWithDataProvider = withDataProvider(FaqPage, {
	endpoint: 'faq'
});

export {
	FaqPageWithDataProvider as default,
	FaqPageWithDataProvider,
	FaqPage
}
