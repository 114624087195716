import {getSupportedDateFormat, isAfterDate, isBeforeDate} from "../vendor/utils/DateUtils";
import {firebaseConnector as firebase} from "../firebase/Firebase";

function getIndexPageByDate(pages) {
    const currentDate = new Date();
    const currentPage = pages.content.find((current, index, arr) => {
        current.endDate = current.finalDate || arr[index + 1].startDate;
        if (index === arr.length - 1) {
            return true;
        }

        return (isAfterDate(currentDate, new Date(getSupportedDateFormat(current.startDate))) && isBeforeDate(currentDate, new Date(getSupportedDateFormat(arr[index + 1].startDate))))
    });
    return currentPage.id;
}

function determinePhase() {
    return firebase.getNode('pages').then(getIndexPageByDate);
}

class PhaseProvider {

    phase = 'dev';
    refs = {};

    constructor() {
        this.setPhase('');
    }

    /**
     * Set phase
     * @param {string} phase
     */
    setPhase(phase) {
        determinePhase().then((autoPhase)=>{
            const phaseToSet = phase || autoPhase || this.getPhase();
            this.setData( phaseToSet );
        });
    }

    /**
     * Get current phase
     * @return {string}
     */
    getPhase() {
        return this.phase;
    }

    /**
     * Update to actual phase
     */
    overwritePhase() {
        determinePhase().then((autoPhase)=>{
            const phaseToSet = autoPhase || this.getPhase();
            this.setData( phaseToSet );
        });
    }

    /**
     * Update all refs
     * @param key
     * @param data
     */
    setData(data) {
        this.phase = data;
        for (let id in this.refs) if(this.refs.hasOwnProperty(id)) {
            const ref = this.refs[id];

            // as state
            if (typeof ref.setState === 'function') {
                ref.setState({phase:this.phase});
            }
            // as setter
            if (typeof ref === 'function') {
                ref(this.phase);
            }
        }
    }

    /**
     * Remove data reference
     * @param id
     */
    unregister(id) {
        delete this.refs[id];
    }

    /**
     * Add Data reference
     * @param {object|function} setter
     * @return {string}
     */
    register(setter) {
        const id = 'ref' + Object.getOwnPropertyNames(this.refs).length;
        this.refs[id] = setter;
        return id;
    }
}

const phaseProvider = new PhaseProvider();
export {
    PhaseProvider,
    phaseProvider
}
