import React, {Component} from 'react';

import './EyeCatcher.scss'
import PropTypes from "prop-types";

class EyeCatcher extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return <div className={'eye-catcher'}>
			<div className={'eye-catcher__wrapper'}>
				<div className={'eye-catcher__firstline'}>{this.props.firstLine}</div>
				<div className={'eye-catcher__secondline'}>{this.props.secondLine}</div>
			</div>
		</div>;
	}
}

EyeCatcher.defaultProps = {
	firstLine: "Halli",
	secondLine: 'Hallo',
	additionalClasses: ''
};

EyeCatcher.propTypes = {
	firstLine: PropTypes.string.isRequired,
	secondLine: PropTypes.string.isRequired,
	additionalClasses: PropTypes.string,
}

export {
	EyeCatcher as default,
	EyeCatcher
}
