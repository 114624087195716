import React, {useState} from 'react';
import {HeaderWithDataProvider as Header} from "../components/molecules/header/Header";
import {Footer} from "../components/molecules/footer/Footer";

const Layout = (props) => {
	return (
		<>
			<Header phase={props.phase}/>
			{props.children}
			<Footer/>
		</>)

};

export {
	Layout as default,
	Layout,
}
