import React, {useEffect, useState} from 'react';
import './Header.scss'
import {Link} from "react-router-dom";
import {Icon} from "../../atoms/icon/Icon";
import {withDataProvider} from "../../../hoc/WithDataprovider";
import {SubscriptionButton} from "../../atoms/subscription-button/SubscriptionButton";
import classNames from "classnames";
import {StorageManager} from "../../../vendor/utils/StorageManager";
import {viewportUtils} from "../../../vendor/utils/ViewportUtils";
import {NavHashLink} from "react-router-hash-link";

function Header(props) {
	const [menuOpen, setMenuOpen] = useState(false);
	const [activeAnchor, setActiveAnchor] = useState("");

	const checkActiveAnchor = () => {
		let activeSection = "";

		for (const link of props.links) {
			if (!link.anchor) continue;

			const element = document.querySelector("#" + link.anchor);
			if (!element) continue;

			const isInViewport = viewportUtils.percentInViewportY(element).percent >= 0.6;
			if (!isInViewport) continue;

			activeSection = link.anchor
		}

		setActiveAnchor(activeSection);
	};

	useEffect(() => {
		function watchScroll() {
			window.addEventListener("scroll", checkActiveAnchor);
		}
		watchScroll();
		return () => {
			window.removeEventListener("scroll", checkActiveAnchor);
		};
	});

	const createNavigation = (links) => {
		return links.map((link, key) => {
			let className = 'header__navigation-item';
			const activeClassName = 'header__navigation-item--active';
			const isAnchor = !!link.anchor;

			if (isAnchor && link.anchor === activeAnchor) {
				className += ' ' + activeClassName;
			}

			return (
				<NavHashLink
					key={key}
					className={className}
					to={link.route}
					smooth
					activeClassName={!isAnchor ? activeClassName : ''}
					onClick={closeNavigation}
				>{link.label}</NavHashLink>
			);
		})
	};

	const closeNavigation = () => {
		setMenuOpen(false);
	};

	const getSubscriptionButton = () => {
		return (
			<div className={'header__button--desktop header__navigation-button'}>
				<SubscriptionButton subscriptionLink={props.subscriptionLink}
									appearance={'primary'}
				/>
			</div>);
	}

	const getSubscriptionButtonMobile = () => {
		return (
			<div className={'header__button--mobile header__navigation-button'}>
				<SubscriptionButton subscriptionLink={props.subscriptionLinkMobile}
									appearance={'primary'}
				/>
			</div>);
	}


	const classes = classNames(
		'header',
		{'header__navigation--is-open': menuOpen},
		props.additionalClasses
	);

	return (
		<>

			<header className={classes}>
				<div className={'header__wrapper'}>
					<div className={'header__navigation-burger'} onClick={() => { setMenuOpen(!menuOpen) }} aria-expanded={menuOpen}>
						<svg width="100%" height="100%" viewBox="0 0 100 100">
							<path className="header__navigation-burger-line header__navigation-burger-line--one"
										d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"/>
							<path className="header__navigation-burger-line header__navigation-burger-line--two" d="M 20,50 H 80"/>
							<path className="header__navigation-burger-line header__navigation-burger-line--three"
										d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"/>
						</svg>
					</div>
					<NavHashLink smooth className={'header__logo-link'} to={'/#stage'}>
						<Icon additionalClasses={'header__logo'} name={'logo'}/></NavHashLink>
					<div className={'header__navigation-wrapper'} id={'navigation'}>

						<nav className={'header__navigation'}>
							<div className={'header__navigation-wrapper'}>
								{StorageManager.getCookie('isAged') === 'true' &&
								<>
									<ul className={'header__navigation-burger-menu'}>
										{createNavigation(props.links)}
									</ul>
									{getSubscriptionButton()}
									{getSubscriptionButtonMobile()}
								</>}
							</div>
						</nav>
					</div>
				</div>
			</header>
		</>
	);
}

Header.defaultProps = {
	links: [],
};


const HeaderWithDataProvider = withDataProvider(Header, {
	endpoint: 'navigation',
});

export {
	HeaderWithDataProvider as default,
	HeaderWithDataProvider,
	Header
}
