import React, {Component} from 'react';
import './VotingBar.scss'
import PropTypes from "prop-types";
import {StorageManager} from "../../../vendor/utils/StorageManager";
import {Icon, ICON_SIZE} from "../../atoms/icon/Icon";

class VotingBar extends Component {
	constructor(props) {
		super(props);
	}

	getPercentageValue(fraction, all){
		if(!fraction) return 0;
		return fraction / all * 100;
	}

	renderLabel(votes, voted) {
		if (votes === undefined) {
			return (
				<div className={'voting-bar__label'}>
					<div className={'voting-bar__loader'}>
						<span>.</span>
						<span>.</span>
						<span>.</span>
					</div>
				</div>
			);
		}

		const suffix = votes === 1 ?  ' Stimme' : ' Stimmen';
		const prefix = voted ? <Icon name={'hook'} size={ICON_SIZE.M} additionalClasses={'voting-bar__label-icon'}/> : '';

		return (
			<div className={'voting-bar__label'}>{prefix}{votes}{suffix}</div>
		);
	}

	render() {
		return (
			<>
				{this.renderLabel(this.props.votes, this.props.voted)}
				<div className={'voting-bar'}>
					<div className="voting-bar__progress"
						  style={{width: this.getPercentageValue(this.props.votes, this.props.totalVotes) + '%'}}> </div>
					<div className={"voting-bar__border"}>
						<div className={"voting-bar__border-end"} />
						<div className={"voting-bar__border-top"} />
						<div className={"voting-bar__border-bottom"} />
					</div>
				</div>
			</>
		)
	}
}

VotingBar.propTypes = {
	/**
	 * **REQUIRED**: name of icon
	 */
	votes: PropTypes.number.isRequired,
	totalVotes: PropTypes.number.isRequired,
	voted: PropTypes.bool.isRequired,

	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.*
	 */
	additionalClasses: PropTypes.string
};

export {
	VotingBar as default,
	VotingBar
}
