/**
 * compare two jsonStrings
 */
const jsonEqual = (a, b) => {
	return JSON.stringify(a) === JSON.stringify(b);
};

/**
 * deep copying of pure objects
 * use only with objects that don't have references to other instances!
 */
const deepCopyPureObject = (value) => {
	return JSON.parse(JSON.stringify(value));
};

/**
 * appending currency to price value
 * Format: € 122.334,-
 * @param value
 * @returns {string}
 */
const currencyFormat = (value) => {

	const formatter = new Intl.NumberFormat('de-DE', {
		style: 'currency',
		currency: 'EUR',
		minimumFractionDigits: 0
	});

	let format = '€ ' + formatter.format(value).replace('€', '');
	// remove &nbsp;
	format =  format.trim() + ',-' ;
	return format;

};

const getQueryVariable = (queryString,variable) => {

	let vars = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
	for (let i = 0; i < vars.length; i++) {
		let pair = vars[i].split('=');
		if (decodeURIComponent(pair[0]) === variable) {
			return decodeURIComponent(pair[1]);
		}
	}
};


const transparentPNG_1x1 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

const isEmpty = (object) => {
	return Object.keys(object).length === 0;
};

const mod = (n, m) => {
	return ((n % m) + m) % m;
};

const getYoutubeIdFromUrl = (url) => {
	const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
	const match = url.match(regExp);
	return (match && match[7].length === 11)? match[7] : false;
};

export {
	jsonEqual,
	deepCopyPureObject,
	currencyFormat,
	transparentPNG_1x1,
	getQueryVariable,
	isEmpty,
	mod,
	getYoutubeIdFromUrl
}




