class ViewportUtils {

	/**
	 * Get Viewport dimensiosn
	 * @returns {{width: number, height: number}}
	 */
	getViewportDimensions() {
		const w = window,
			d = document,
			e = d.documentElement,
			g = d.getElementsByTagName('body')[0],
			x = w.innerWidth || e.clientWidth || g.clientWidth,
			y = w.innerHeight || e.clientHeight || g.clientHeight;

		return {width: x, height: y};
	}

	/**
	 * Element in viewport on y axis?
	 *
	 * @param element {Element}
	 * @param viewBox {Element}
	 * @returns {boolean} Element is smaller than viewport but fully visible in it
	 */
	fullyInViewportY(element, viewBox = null) {

		// Element dimensions
		const elementRect = element.getBoundingClientRect();

		// Viewport height
		let viewBoxHeight = 0;
		let fromTop = 0;

		if (viewBox) {
			let viewBoxRect = viewBox.getBoundingClientRect();
			fromTop = elementRect.top - viewBoxRect.top;
			viewBoxHeight = viewBoxRect.height;
		} else {
			fromTop = elementRect.top;
			viewBoxHeight = window.innerHeight;
		}


		// Pixels content is located away from viewport border
		const fromBottom = viewBoxHeight - (fromTop + elementRect.height);
		return this.fullyInViewport(fromTop, fromBottom);
	}

	/**
	 * Element is fully in viewport
	 *
	 * @param fromStart {number} element top/left
	 * @param fromEnd {number} referenceDimension - (fromStart + elementDimension)
	 * @returns {boolean} Element is smaller than viewport but fully visible in it
	 */
	fullyInViewport(fromStart, fromEnd) {
		return (fromStart >= 0 && fromEnd >= 0);
	}

	/**
	 * Amount of element in viewport on y axis
	 *
	 * @param element {Element}
	 * @param viewBox {Element}
	 * @returns {{percent:number, fullInViewport:boolean}} percent value as number between 0 and 1
	 */
	percentInViewportY(element, viewBox = null, offsetPercentTop= 0, offsetPercentBottom= 0) {

		// Element dimensions
		const elementRect = element.getBoundingClientRect();
		// Viewport height
		let viewBoxHeight = 0;
		let fromTop = 0;

		if (viewBox) {
			let viewBoxRect = viewBox.getBoundingClientRect();
			fromTop = elementRect.top - viewBoxRect.top;
			viewBoxHeight = viewBoxRect.height;
		} else {
			fromTop = elementRect.top;
			viewBoxHeight = window.innerHeight;
		}

		const offsetTop = viewBoxHeight * offsetPercentTop;
		const offsetBottom = viewBoxHeight * offsetPercentBottom;

		// Pixels content is located away from viewport border
		const fromBottom = viewBoxHeight - (fromTop + elementRect.height);
		return {
			percent: this.percentInViewport(viewBoxHeight, fromTop, fromBottom),
			fullInViewport: (fromTop >= offsetTop && fromBottom >= offsetBottom)
		};
	}

	percentTopInViewport(element, viewBox = null) {

	}

	/**
	 * Amount of element in viewport on y axis
	 *
	 * @param element {Element}
	 * @param viewBox {Element}
	 * @returns {{percent:number, fullInViewport:boolean}}
	 * 			percent: value between 0 and 1
	 * 			fullInViewport: true if element full visible in viewbox
	 */
	percentInViewportX(element, viewBox, offset = 0) {

		// Element dimensions
		const elementRect = element.getBoundingClientRect();
		// Viewport width
		let viewBoxWidth = 0;
		let fromLeft = 0;

		if (viewBox) {
			let viewBoxRect = viewBox.getBoundingClientRect();
			fromLeft = elementRect.left - viewBoxRect.left;
			viewBoxWidth = viewBoxRect.width - offset;
		} else {
			fromLeft = elementRect.left;
			viewBoxWidth = window.innerWidth;
		}

		// Pixels content is located away from viewport border
		const fromRight = viewBoxWidth - (fromLeft + elementRect.width);
		return {
			percent: this.percentInViewport(viewBoxWidth, fromLeft, fromRight),
			fullInViewport: (fromLeft >= 0 && fromRight >= 0)
		};
	}

	/**
	 * Amount of element in viewport
	 *
	 * @param referenceDimension {number} window width/height
	 * @param fromStart {number} element top/left
	 * @param fromEnd {number} referenceDimension - (fromStart + elementDimension)
	 * @returns {number} percent value as number between 0 and 1
	 */
	percentInViewport(referenceDimension, fromStart, fromEnd) {

		let pixesInViewport = 0;

		// Elements stretches beyond viewport
		if (fromStart < 0 && fromEnd < 0) {

			// Fills entire viewport
			pixesInViewport = referenceDimension;
		} else if (fromStart <= 0 && fromEnd >= 0) {

			// Fills viewport partially from top
			pixesInViewport = referenceDimension - fromEnd;
		} else if (fromStart >= 0 && fromEnd <= 0) {

			// Fills viewport partially from bottom
			pixesInViewport = referenceDimension - fromStart;
		} else {

			// Element smaller than viewport and both edges are in viewport
			pixesInViewport = referenceDimension - fromEnd - fromStart;
		}

		// Catch if not in viewport, negative values -> 0 percent
		pixesInViewport = Math.max(pixesInViewport, 0);

		// Calculate percent in viewport
		return pixesInViewport / referenceDimension;
	}

	/**
	 * Find element index most in viewport
	 *
	 * @param elements {Array.<Element>}
	 * @returns {number} index of element most in viewport
	 */
	mostInViewPortY(elements) {
		if (elements && elements.length) {
			let mostIdx = 0;
			let mostPercent = 0;
			for (let i = 0; i < elements.length; i++) {
				let elemPercent = this.percentInViewportY(elements[i]);
				if (elemPercent > mostPercent) {
					mostPercent = elemPercent;
					mostIdx = i;
				}
			}

			return mostIdx;
		}
		return 0;
	}

	/**
	 * Get scrolled percent
	 *
	 * @returns {number} as value between 0 and 1
	 */
	scrollPercentY() {
		var maxHeight = document.body.scrollHeight - window.innerHeight;
		var current = this.scrollTop();
		return current / maxHeight;
	}

	/**
	 * Get document scroll top
	 *
	 * @returns {number}
	 */
	scrollTop() {
		var top = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
		return top;
	}
}

const viewportUtils = new ViewportUtils();


export {
	ViewportUtils as default,
	ViewportUtils,
	viewportUtils
}
