/**
 * Convert utc unsafe date to utc safe date to avoid date changes due to user timezone
 *
 * @param utcUnsafeDate {string|number}
 */
const getUtcSafeDate = (utcUnsafeDate) => {


	// set date to milliseconds if date was given as days or seconds from 1970
	if (typeof utcUnsafeDate === 'number' && utcUnsafeDate % 1 === 0 && utcUnsafeDate < 9000000000) {
		if (utcUnsafeDate < 200000) {
			utcUnsafeDate *= 86400000; //if date is in days since 01.01.1970
		} else {
			utcUnsafeDate *= 1000; //if date is in seconds since 01.01.1970
		}
	}

	const utcSafeDate = new Date(utcUnsafeDate);

	//add difference from system time to UTC timezone. Date from server should be UTC time
	// TODO: !important: Check timeZoneOffset
	// Safaris GMT is +2 / Chrome Firefox etc  ist GMT +1
	// https://medium.com/@rikdriever/javascript-date-issue-since-chrome-67-50aa555799d0
	const utc_diff = utcSafeDate.getTime() + (utcSafeDate.getTimezoneOffset() * 60000);
	//const utc_diff = utcSafeDate.getTime() + (new Date(0).getTimezoneOffset() * 60000);

	utcSafeDate.setTime(utc_diff);

	return utcSafeDate;
};

/**
 * get lower of two moment instances
 *
 * @param date1 {Date}
 * @param date2 {Date}
 * @returns {Date}
 */
const minDate = (date1, date2) => new Date(Math.min(date1.getTime(), date2.getTime()));

/**
 * get higher of two moment instances
 *
 * @param date1 {Date}
 * @param date2 {Date}
 * @returns {Date}
 */
const maxDate = (date1, date2) => new Date(Math.max(date1.getTime(), date2.getTime()));

/**
 * Date 1 is before date 2
 *
 * @param date1 {Date}
 * @param date2 {Date}
 * @returns {boolean}
 */
const isBeforeDate = (date1, date2) => date1.getTime() < date2.getTime();

/**
 * Date 1 is equal to date 2
 *
 * @param date1 {Date}
 * @param date2 {Date}
 * @returns {boolean}
 */
const isSameDate = (date1, date2) => date1.getTime() === date2.getTime();

/**
 * Date 1 is after date 2
 *
 * @param date1 {Date}
 * @param date2 {Date}
 * @returns {boolean}
 */
const isAfterDate = (date1, date2) => date1.getTime() > date2.getTime();

/**
 * Date 1 in relation to date 2 in milliseconds
 *
 * @param date1 {Date}
 * @param date2 {Date}
 * @returns {boolean}
 */
const diffDate = (date1, date2) => date1.setHours(0) - date2.setHours(0);

/**
 * Date 1 in relation to date 2 in days
 *
 * @param date1 {Date}
 * @param date2 {Date}
 * @returns {boolean}
 */
const diffDateDays = (date1, date2) => Math.round(diffDate(date1, date2) / (1000 * 60 * 60 * 24));

/**
 * Check if date is on the weekend
 * @param date {Date}
 * @returns {boolean}
 */
const isWeekendDate = (date) => (date.getDay() === 6) || (date.getDay() === 0);

/**
 * Convert to iso Strig and provide only date
 *
 * @param date {Date}
 * @returns {string} in format YYYYY-MM-DD
 */
const toISODateStr = (date) => {
	return new Date(date.getTime() + (-1 * date.getTimezoneOffset() * 60 * 1000)).toISOString().substr(0, 10)
};

/**
 * Convert ISO Date String to unix time in seconds
 *
 * @param isoDateStr {string}
 * @returns {number}
 */
const unixSecondsFromISODateStr = (isoDateStr) => Math.round(parseInt(new Date(isoDateStr).getTime() / 1000) / 86400);



const toGermanFullDateString = (date, useSafeDate = true) => {
	const dateOptions = {weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric'};

	if (useSafeDate) {
		return getUtcSafeDate(date).toLocaleDateString('de-DE', dateOptions).replace('.,', ' ')

	} else {
		return new Date(date).toLocaleDateString('de-DE', dateOptions).replace('.,', ' ')
	}

};

/**
 * Convert to german date string in format DD.MM.YYYY
 *
 * @param date {Date}
 * @returns {string}
 */
const toGermanDateStr = (date, useSafeDate = true) => {

	const dateOptions = {day: '2-digit', month: '2-digit', year: 'numeric'};
	if (useSafeDate) {
		return getUtcSafeDate(date).toLocaleDateString('de-DE', dateOptions);
	} else {
		return new Date(date).toLocaleDateString('de-DE', dateOptions);
	}
};

const monthNamesLong = [
	"Januar", "Februar", "März",
	"April", "Mai", "Juni", "Juli",
	"August", "September", "Oktober",
	"November", "Dezember"
];

// Day of the week (from 0 to 6, Sunday is 0, Monday is 1, etc)
const dayNamesShort = [
	"So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"
];

const getDayPadded = (day) => {
	return day < 10 ? `0${day}` : day
};

const getMonthPadded = (monthIndex) => {
	return monthIndex < 9 ? `0${monthIndex + 1}` : monthIndex + 1;
};

const getWeekday = (date) => {
	date = getUtcSafeDate(date);
	return dayNamesShort[date.getDay()];
};

const getSmallFormatDate = (date) => {
	date = getUtcSafeDate(date);
	return `${date.getDate()}.${getMonthPadded(date.getMonth())}.${date.getFullYear()}`;
};

const getSmallPaddedFormatDate = (date) => {
	date = getUtcSafeDate(date);
	return `${getDayPadded(date.getDate())}.${getMonthPadded(date.getMonth())}.${date.getFullYear()}`;

};

const getLargeFormatDate = (date) => {
	date = getUtcSafeDate(date);
	return `${getDayPadded(date.getDate())}.${monthNamesLong[date.getMonth()]}.${date.getFullYear()}`;
};

const getXLargeFormatDate = (date) => {
	date = getUtcSafeDate(date);
	const dayShort = dayNamesShort[date.getDay()];
	const largeDate = `${getDayPadded(date.getDate())}.${monthNamesLong[date.getMonth()]}.${date.getFullYear()}`;
	return `${dayShort}.,${largeDate}`;

};

const getSupportedDateFormat = (date) => {

	return date.replace(/-/g, "/");
};


export {
	getUtcSafeDate,
	minDate,
	maxDate,
	isAfterDate,
	isBeforeDate,
	isSameDate,
	diffDate,
	diffDateDays,
	isWeekendDate,
	toISODateStr,
	unixSecondsFromISODateStr,
	toGermanFullDateString,
	toGermanDateStr,
	getDayPadded,
	getMonthPadded,
	getWeekday,
	getSmallFormatDate,
	getSmallPaddedFormatDate,
	getLargeFormatDate,
	getXLargeFormatDate,
	getSupportedDateFormat
}
