import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import './Modal.scss';
import {Icon, ICON_SIZE} from "../icon/Icon";
import classNames from "classnames";
import PropTypes from "prop-types";

const ANIMATION_DURATION = 300;

class Modal extends Component {

	/**
	 * @param {object}        props
	 * @param {function}      props.toggleHandler
	 * @param {string}        props.size
	 * @param {string}        props.additionalClasses
	 */
	constructor(props) {
		super(props);

		this.closeHandler = this.closeHandler.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.freezeViewPort = this.freezeViewPort.bind(this);

		this.state = {
			willClose: false
		};

		this.scrollY = 0;
	}

	/**
	 * animated closing
	 */
	closeHandler() {
		this.setState({
			willClose: true
		});

		setTimeout(() => {
			this.props.onClose()
		}, ANIMATION_DURATION);
	}

	/**
	 * renders specific closeButton depending on this.props.size
	 * @param modalType
	 * @returns {*}
	 */
	renderCloseButton() {

		return <div className='modal__close' onClick={this.closeHandler}>
			<Icon name={'close'}/>
		</div>;
	}

	/**
	 * close modal if key ESC was clicked
	 *
	 * @param {Event} e
	 */
	onKeyDown(e) {
		if (e.which === 27) {
			this.closeHandler();
		}
	}

	/**
	 * lock scrolling via class on body so only the modal can be scrolled
	 * except ios-safari, so we need the freezeViewport Method here.
	 */
	toggleScrollLock(locked) {


		if (locked) {
			document.body.classList.add('lock-scroll');
			this.scrollY = window.scrollY;
			document.body.addEventListener("touchmove", this.freezeViewPort);
		} else {
			document.body.classList.remove('lock-scroll');
			document.body.removeEventListener("touchmove", this.freezeViewPort);
		}
	}

	/**
	 * prevent scrolling of background while modal is open
	 * @param e
	 */
	freezeViewPort(e) {
		e.preventDefault();
		window.scrollTo({
			top: this.scrollY
		});
	};

	componentDidMount() {
		if(this.props.isBlocking) {
			this.toggleScrollLock(true);
			window.addEventListener('keydown', this.onKeyDown);
		}

	}

	componentWillUnmount() {
		this.toggleScrollLock(false);
		window.removeEventListener('keydown', this.onKeyDown);
		document.body.removeEventListener("touchmove", this.freezeViewPort);
	}

	render() {
		const classes = classNames(
			'modal',
			`modal--theme-${this.props.theme}`,
			{'modal--will-close': this.state.willClose},
			`modal--${this.props.size}`,
			this.props.additionalClasses,
		);

		const animationTimeStyle = {
			animationDuration: `${ANIMATION_DURATION}ms`,
			transitionDuration: `${ANIMATION_DURATION}ms`
		};

		return (
			ReactDOM.createPortal(
				<div className={classes}>
					{this.props.isBlocking &&
					<div className='modal__bg' onClick={this.closeHandler} style={animationTimeStyle}/>}
					<div className={classNames('modal__layer', `modal__layer--${this.props.size}`)}
						 style={animationTimeStyle}>
						{this.renderCloseButton(this.props.size)}
						<div className='modal__content' id={this.props.id}>
							{this.props.render && this.props.render({modalCloseHandler: this.closeHandler})}
							{this.props.children}
						</div>
					</div>
				</div>
				, document.body)
		)
	}
}

Modal.propTypes = {
	/**
	 * REQUIRED: function to toggle visibility outside the modal class
	 */
	onClose: PropTypes.func.isRequired,

	/**
	 * OPTIONAL: set width size of modal layer
	 * @default 'big'
	 */
	size: PropTypes.oneOf(['big', 'medium', 'small', 'auto', 'fullsize', 'fullscreen']),

	/**
	 * OPTIONAL: set theme for modal layer
	 * @default 'light'
	 */
	theme: PropTypes.oneOf(['light', 'medium', 'dark', 'dark-grey']),

	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.
	 */
	additionalClasses: PropTypes.string,

	/**
	 * modal id can be used for tooltip parantSelector
	 */
	id: PropTypes.string,

	/**
	 * flag if modal should have a blocking background
	 * @default true
	 */
	isBlocking: PropTypes.bool
};

Modal.defaultProps = {
	additionalClasses: '',
	size: 'big',
	theme: 'light',
	isBlocking: true,
	id: 'modal'
};

export {
	Modal as default,
	Modal
}
