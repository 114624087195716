import './App.scss';
import 'react-app-polyfill/ie11';
import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import {MainPage} from "./pages/MainPage";
import {PrivacyPolicyPage} from "./pages/privacy-policy/PrivacyPolicyPage";
import FaqPage from "./pages/faq/FAQPage";
import withTitleChange from "./hoc/WithTitleChange";
import ScrollToTop from "./vendor/utils/ScrollToTop";
import {ImprintPage} from "./pages/imprint/ImprintPage";
import {AgeGatePage} from "./pages/agegate/AgeGatePage";
import {TimelinePage} from "./pages/timeline/TimelinePage";

const faqPage = withTitleChange(FaqPage, {titleName: 'FAQ'});
const mainPage = withTitleChange(MainPage, {titleName: 'Nachtdenken'});
const privacyPolicyPage = withTitleChange(PrivacyPolicyPage, {titleName: 'Datenschutz'});
const imprintPage = withTitleChange(ImprintPage, {titleName: 'Impressum'});
const ageGatePage = withTitleChange(AgeGatePage, {titleName: 'Altersfreigabe'});
const timelinePage = withTitleChange(TimelinePage, {titleName: 'Ablauf'});

const App = () => {
	return (
		<>
			<BrowserRouter>
				<ScrollToTop/>
				<Switch>
					<Route path="/faq/" component={faqPage}/>
					<Route path="/datenschutz/" component={privacyPolicyPage}/>
					<Route path="/impressum/" component={imprintPage}/>
					<Route path="/agegate/" component={ageGatePage}/>
					<Route path="/timeline/" component={timelinePage}/>

					{/* TODO: delete after testing*/}
					<Route path="/:phase" component={mainPage}/>


					<Route path="/" component={mainPage}/>
				</Switch>
			</BrowserRouter>
		</>
	);
};

export default App;
