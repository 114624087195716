import React, {Component} from 'react';

import './VideoGallery.scss'
import {withDataProvider} from "../../../hoc/WithDataprovider";
import {VideoTeaser} from "../../molecules/video-teaser/VideoTeaser";

class VideoGallery extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return <section className={'video-gallery'} id={this.props.anchor}>
			<div className={'container'}>
				<div className={'video-gallery__content-wrapper'}>
				{Boolean(this.props.headline) && <div className={'h2 video-gallery__headline'}>{this.props.headline}</div>}
				</div>
				<div className={'video-gallery__teasers'}>
					{this.props.list.map((item, key) =>
						<VideoTeaser key={key}
									 additionalClasses={"video-gallery__teaser"}
									 {...item}
						/>
						)}
				</div>
			</div>
		</section>;
	}
}

VideoGallery.defaultProps = {
	headline: null,

	list: [
		{
			img: "https://img.youtube.com/vi/XSqi5s3rfqk/0.jpg",
			videoUrl: "https://www.youtube.com/watch?v=XSqi5s3rfqk"
		},
		{
			img: "https://img.youtube.com/vi/BSF5yoD-vC4/0.jpg",
			videoUrl: "https://www.youtube.com/watch?v=BSF5yoD-vC4"
		},
		{
			img: "https://img.youtube.com/vi/7ecYoSvGO60/0.jpg",
			videoUrl: "https://www.youtube.com/watch?v=7ecYoSvGO60"
		}
	]
};

const VideoGalleryWithDataProvider = withDataProvider(VideoGallery, {
	endpoint: 'video-gallery'
});

export {
	VideoGalleryWithDataProvider as default,
	VideoGalleryWithDataProvider,
	VideoGallery
}


