import React, {Component} from 'react';
import Image from '../../atoms/image/Image';

import './Sponsors.scss';

const imageSizes = {
	default: '4rem',
	desktop: '50vw',
	tablet: '75vw'
};

const ratio = {width: 4, height: 3}


class Sponsors extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return <section className={'sponsors'} id={this.props.anchor}>
			<div className={'container'}>
				<div className={'h2 sponsors__headline'}>
					<span className={'h2 sponsors__headline--colored'}>
						Initiiert&ensp;
					</span>
					von:
				</div>
				<a href={'https://www.jagermeister.com/de-DE/save-the-night'} target={'_blank'}>

					<Image
						sizes={imageSizes}
						additionalClasses={'sponsors__image sponsors__image--big-margin'}
						srcSet={this.props.initiator}
						alt={'Initiiert durch "SAVE THE NIGHT"'}
						ratio={ratio}
					/>
				</a>
				<div className={'h2 sponsors__headline'}>
					<div className={'sponsors__headline--colored'}>
						Unterstützt&ensp;
					</div>
					durch:
				</div>
				<div className={'sponsors__container'}>
					{this.props.sponsors.map((item, index) => (
						<div className={'sponsors__item'} key={index}>

							<Image
								sizes={imageSizes}
								additionalClasses={`sponsors__image sponsors__image--margin`}
								srcSet={item["srcSet"]}
								alt={item["alt"]}
								ratio={ratio}
							/>

							{item["hr"] && <hr className={'sponsors__hr'}/>}
						</div>
					))}
				</div>
			</div>
		</section>;
	}
}

Sponsors.defaultProps = {
	sponsors: [
		{
			"srcSet": {
				desktop: '/images/sponsor-hellofresh.jpg',
				tablet: '/images/sponsor-hellofresh.jpg',
			},
			"alt": "Hello Fresh",
			"hr": false
		},
		{
			"srcSet": {
				desktop: '/images/sponsor-askhelmut.jpg',
			},
			"alt": "Ask Helmut",
			"hr": false
		},
		{
			"srcSet": {
				desktop: '/images/sponsor-rausgegangen.jpg',
			},
			"alt": "Rausgegangen",
			"hr": false
		}, {
			"srcSet": {
				desktop: '/images/sponsor-vibelab.jpg',
			},
			"alt": "vibelab",
			"hr": false
		}
	],
	initiator: {
		desktop: '/images/save-the-night.jpg',
		tablet: '/images/save-the-night.jpg',
	}
};

export {
	Sponsors as default,
	Sponsors
}
