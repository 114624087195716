import classNames from "classnames";
import {StorageManager} from "../../vendor/utils/StorageManager";
import {AgeGate} from "./AgeGate";
import {Layout} from "../Layout";

const AgeGatePage = (props) => {

	const classes = classNames(
		'agegate-page',
	);

	StorageManager.deleteCookie('isAged');
	return (
		<>
			<Layout>
				<div className={classes}>
					<AgeGate/>
				</div>
			</Layout>
		</>
	);

};

export {
	AgeGatePage as default,
	AgeGatePage
}
