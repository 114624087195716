import React, {useRef} from 'react';
import Lottie from 'lottie-react';
import circleArrow from './animations/circleArrow.json'
import circle from './animations/circle.json'
import thinkStroke from './animations/thinkStroke.json'
import stroke from './animations/stroke.json'
import bracketLeft from './animations/bracketLeft.json'
import bracketRight from './animations/bracketRight.json'
import teamUp from './animations/teamUp.json'
import classNames from "classnames";

const Animation = (props) => {

	const lottieRef = useRef(null);

	let animationData = null;
	switch (props.type) {
		case 'circleArrow':
			animationData = circleArrow;
			break;
			case 'circle':
			animationData = circle;
			break;
		case 'thinkStroke':
			animationData = thinkStroke;
			break;
		case 'stroke':
			animationData = stroke;
			break;
		case 'bracket-left':
			animationData = bracketLeft;
			break;
		case 'bracket-right':
			animationData = bracketRight;
			break;
		case 'teamUp':
			animationData = teamUp;
			break;
		default:
			animationData = circleArrow;
	}

	if (props.isPlaying) {

		if (props.playForwards) {
			lottieRef.current.setDirection(1);
			lottieRef.current.setSpeed(1);
		} else {
			lottieRef.current.setDirection(-1);
			lottieRef.current.setSpeed(2);
		}


		setTimeout(() => {
			lottieRef.current.play();
		}, props.delay);
	}


	const classes = classNames(
		'humanized-text__animation',
		{[`humanized-text__animation--${props.type}`]: props.type}
	);

	return <span className={classes}>
		<Lottie lottieRef={lottieRef}
						animationData={animationData}
						autoplay={false}
						loop={false}

		/>
	</span>
}

export default Animation
