import React, {Component} from 'react';
import {Image} from '../../atoms/image/Image';
import {SubscriptionButton} from "../../atoms/subscription-button/SubscriptionButton";

import './Prices.scss'

class Prices extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<section className={'prices'} id={this.props.anchor}>
				<div className={'container'}>
					<div className={'prices__head'}>
						<div className={'h2 prices__headline'}>
							<span className={"prices__headline--colored"}>
								Dein Einsatz&nbsp;
							</span>
							wird belohnt
						</div>
						<div className={'h3 prices__subheadline'}>Preisgelder</div>
						<div className={'prices__introduction inter'}>
							Die besten Konzepte gehen in eine detaillierte Realisierungsprüfung und werden zusätzlich mit folgenden Preisgeldern prämiert:
						</div>
					</div>
					<div className={'prices__container'}>
						<div className={'prices__item'}>
							<div className={'prices__label'}><u>1. Preis</u></div>
							<div className={'h2 prices__value'}>7.500 €</div>
						</div>
						<div className={'prices__item'}>
							<div className={'prices__label'}><u>2. Preis</u></div>
							<div className={'h2 prices__value'}>5.000 €</div>
						</div>
						<div className={'prices__item'}>
							<div className={'prices__label'}><u>3. Preis</u></div>
							<div className={'h2 prices__value'}>2.500 €</div>
						</div>
					</div>
					{/* Remove Goody mention until further notice
					<div className={'prices__goodies'}>
						<div className={'h3 prices__goodies-headline'}>Goodies</div>
						<div className={'prices__introduction inter'}>
							Alle, die sich mit einem Konzept beteiligen,
							können sich außerdem über ein Jägermeister #SAVETHENIGHT Package freuen!
						</div>
					</div>
					*/}
					{/*
					<Image sizes={{default: '4rem'}} ratio={{width: 4, height: 3}} additionalClasses={'prices__image'} alt={'Goodies Bild'} url={''} />
					*/}
					<SubscriptionButton subscriptionLink={this.props.subscriptionLink}
															label={'Jetzt anmelden'}
															additionalClasses={'prices__button'}
					/>
				</div>
			</section>
		);
	}
}
export {
	Prices as default,
	Prices
}


