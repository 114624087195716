import React, {Component} from 'react';

import './VideoGallery.scss'
import {withDataProvider} from "../../../hoc/WithDataprovider";
import {VideoTeaser} from "../../molecules/video-teaser/VideoTeaser";

class VideoStream extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return <section className={'video-gallery'} id={this.props.anchor}>
			<div className={'container'}>
				<div className={'video-gallery__content-wrapper'}>
				{Boolean(this.props.headline) && <div className={'h2 video-gallery__headline'}>{this.props.headline}</div>}
				</div>
				<div className={'video-gallery__teasers'}>
					{this.props.list.map((item, key) => <VideoTeaser key={key}
																													 additionalClasses={"video-gallery__teaser"} {...item}/>)}
				</div>
			</div>
		</section>;

	}
}

VideoStream.defaultProps = {
	headline: null,

	list: [
		{
			img: "https://img.youtube.com/vi/XSqi5s3rfqk/0.jpg",
			videoUrl: "https://www.youtube.com/watch?v=XSqi5s3rfqk"
		}
	]
};

const VideoStreamWithDataProvider = withDataProvider(VideoStream, {
	endpoint: 'video-stream'
});

export {
	VideoStreamWithDataProvider as default,
	VideoStreamWithDataProvider,
	VideoStream
}


