import React from 'react';
import './SubscriptionButton.scss'
import PropTypes from "prop-types";
import {ButtonAnchor} from "../button/ButtonAnchor";

function SubscriptionButton(props) {

	const label = props.label || props.subscriptionLink?.label;

	const clickHandler = ()=>{
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({'event':'click-eventbrite','label':label});
	};

	return (
		<>
			{props.subscriptionLink &&
				<ButtonAnchor
					additionalClasses={props.additionalClasses}
					onClick={clickHandler}
					label={label}
					appearance={props.appearance}
					width={'fit'}
					target={props.subscriptionLink.target}
					href={props.subscriptionLink.url}
				/>
			}
		</>
	);
}

SubscriptionButton.propTypes = {
	/**
	 * SubscriptionButton URL
	 *
	 * @example: 'https://foo@bar.de' || '/foo/bar'
	 */
	subscriptionLink: PropTypes.object,

	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.
	 */
	additionalClasses: PropTypes.string,

	/**
	 * OPTIONAL:
	 */
	appearance: PropTypes.oneOf(['primary', 'secondary', 'important', 'main-navigation', 'dark']),


};

SubscriptionButton.defaultProps = {
	appearance: 'primary'
};

export {
	SubscriptionButton as default,
	SubscriptionButton,
}
