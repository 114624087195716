import React, {Component} from 'react';
import './Collapsible.scss'
import {Icon, ICON_SIZE} from "../icon/Icon";
import PropTypes from "prop-types";
import classNames from "classnames";
import ReactMarkdown from "react-markdown";

class Collapsible extends Component {

	renderToggleButton() {
		let button = <span className="collapsible__toggle">
				<Icon name={'square-plus'} additionalClasses={'open'} size={ICON_SIZE.L}/>
				<Icon name={'square-minus'} additionalClasses={'close'} size={ICON_SIZE.L}/>
			</span>;


		return button

	}

	render() {

		const isCollapsible = !!this.props.content;

		let classes = classNames(
			'collapsible',
			this.props.additionalClasses
		);

		return (
			<div className={classes}>
				<input type='checkbox' id={this.props.id}/>

				<label className='collapsible__head' htmlFor={this.props.id}>
					{isCollapsible && this.renderToggleButton()}
					<div className={'collapsible__head-content'}>
						{this.props.headContent}
						<hr/>
					</div>

				</label>
				{isCollapsible &&
				<div className="collapsible__content">
					<div className="collapsible__inner-content">
						<ReactMarkdown>{this.props.content}</ReactMarkdown>
					</div>
				</div>
				}
			</div>
		)
	}
}

Collapsible.propTypes = {
	/**
	 * content of collapsible JSX / HTML
	 */
	content: PropTypes.node,
	/**
	 * headContent of collapsible JSX / HTML
	 */
	headContent: PropTypes.node.isRequired,
	/**
	 * id of collapsible
	 */
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/**
	 * OPTIONAL: add additional classes for this component here.
	 * *Use this prop for special css-classes, which are not defined by default.*
	 */
	additionalClasses: PropTypes.string,
};

export {
	Collapsible as default,
	Collapsible
}
