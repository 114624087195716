import React, {Component} from 'react';

import './Questions.scss'
import PropTypes from "prop-types";
import classNames from "classnames";
import {SubscriptionButton} from "../../atoms/subscription-button/SubscriptionButton";

class Questions extends Component {
	constructor(props) {
		super(props);
	}

	render() {

		const classes = classNames(
			'questions__section',
			this.props.additionalClasses
		);

		return (
			<section className={classes} id={this.props.anchor}>
				<div className={'container questions__container'}>
					<div className={'questions'}>
						<div className={'question'}>
							<div className={'question__background question__background--when'}/>
							<div className={'question__content'}>
								<div className={'question__text'}>
									<h2 className={'question__headline'}>
										{this.props.headlineWhen}
									</h2>
									<div className={'question__subline'}>
										{this.props.sublineWhen}
									</div>
								</div>
							</div>
						</div>
						<div className={'question'}>
							<div className={'question__background question__background--where'}/>
							<div className={'question__content'}>
								<div className={'question__text'}>
									<h2 className={'question__headline'}>
										{this.props.headlineWhere}
									</h2>
									<div className={'question__subline'}>
										{this.props.sublineWhere}
									</div>
								</div>
							</div>
						</div>
						<div className={'question'}>
							<div className={'question__background question__background--who'}/>
							<div className={'question__content'}>
								<div className={'question__text'}>
									<h2 className={'question__headline'}>
										{this.props.headlineWho}
									</h2>
									<div className={'question__subline'}>
										{this.props.sublineWho}
									</div>
								</div>
							</div>
						</div>
					</div>
					<SubscriptionButton subscriptionLink={this.props.subscriptionLink}
										additionalClasses={'questions__cta'}/>
				</div>
			</section>
		);
	}
}

Questions.defaultProps = {
	headlineWhen: "Wann?",
	sublineWhen: "Vom 15.01. bis 17.01.2021",

	headlineWhere: "Wo?",
	sublineWhere: "Der gesamte Denkathon wird online via Zoom, Slack, WhatsApp und Mural stattfinden.",

	headlineWho: "Wer?",
	sublineWho: "Alle, denen das Nachtleben am Herzen liegt.."
};

Questions.propTypes = {
	headlineWhen: PropTypes.string.isRequired,
	headlineWhere: PropTypes.string.isRequired,
	headlineWho: PropTypes.string.isRequired,
	sublineWhen: PropTypes.string.isRequired,
	sublineWhere: PropTypes.string.isRequired,
	sublineWho: PropTypes.string.isRequired,
	additionalClasses: PropTypes.string,
	background: PropTypes.oneOf(['when', 'where', 'who'])
}

export {
	Questions as default,
	Questions
}


