import React from 'react';
import {Link} from 'react-router-dom';
import './Footer.scss'

const Footer = () => {
	return (
		<footer className={'footer'}>
			<nav className={'footer__navigation'}>
				<div className={'footer__navigation-item'}>
					<div className={'footer__item'}>
						<Link to={'/impressum'}>Impressum</Link>
					</div>
					<span className={'footer__delimiter'}></span>
				</div>
				<div className={'footer__navigation-item'}>
					<div className={'footer__item'}>
						<a href={'/datenschutz'} target={'_self'}>Datenschutz</a>
					</div>
				</div>
			</nav>
			<div className={'footer__subline'}>
				Für Presseanfragen klicke bitte <a href="mailto:katrin.mirtschink@jaegermeister.de">hier</a>.
			</div>
			<div className={'footer__subline'}>
				Für mehr interessante Events und Informationen, kannst du dich <a href="https://www.jagermeister.com/de-DE/newsletter" target='_blank'>hier</a> zum Newsletter anmelden.
			</div>
			<div className={'footer__subline'}>
				<a href={"https://www.massvoll-geniessen.de/"} target={'_blank'}>Drink responsibly!</a>
			</div>
		</footer>
	);
};

export {
	Footer as default,
	Footer
}
